import React from 'react';
import { MultiSelect } from 'react-multi-select-component';
import isArray from 'lodash/isArray';
import {
  ADVANCE_FILTER,
  CUSTOMER_VIEW_SHARE_IB,
  VIEW,
} from 'constants/constants';
import { formatShareOptionLabel } from 'utils/utils';
const MultiSelectDropdown = ({
  options,
  selected,
  setSelected,
  hasSelectAll,
  disabled,
  page,
  loading = false,
}) => {
  const customValueRenderer = (selected, _options) => {
    const isAllselected =
      isArray(_options) &&
      (selected?.length === 0 || _options?.length === selected?.length);
    if (disabled) {
      return 'All';
    }
    if (page === 'org-chart' && selected?.length === 0) {
      return 'Select';
    }
    if (page === CUSTOMER_VIEW_SHARE_IB && selected?.length === 0) {
      return 'Share with ...';
    }
    if (page === ADVANCE_FILTER && selected?.length === 0) {
      return 'Select KPI';
    }

    const selectedItems = !isArray(selected)
      ? ''
      : isAllselected
      ? 'All'
      : selected?.length === 1
      ? selected?.[0]?.label
      : `Multiple Selected`;
    return selectedItems;
  };
  const CustomClearIcon = () => (
    <span className='title text__grey mb-1'>x</span>
  );
  const CustomArrow = ({ expanded }) => (
    <>
      {expanded ? (
        <i className='icon icon__chevron-up' />
      ) : (
        <i className='icon icon__chevron-down' />
      )}
    </>
  );
  const filterOption = (options, filter) => {
    if (!filter) {
      return options;
    }
    const isAddress = options[0]?.address;
    const expression = new RegExp(filter, 'i');
    if (!isAddress) {
      return options.filter((opt) => opt?.label?.match(expression));
    } else {
      return options.filter(
        (opt) => opt?.label?.match(expression) || opt.address?.match(expression)
      );
    }
  };
  return (
    <MultiSelect
      selectionLimit={1}
      options={options.map((val) => ({
        ...val,
        disabled: loading || val.disabled,
      }))}
      value={selected}
      onChange={setSelected}
      filter={''}
      filterOptions={filterOption}
      valueRenderer={customValueRenderer}
      labelledBy='Select'
      className={`gne-dropdown  ${disabled || loading ? 'disabled' : ''} ${
        page === ADVANCE_FILTER ? 'title__small' : 'title__normal'
      }`}
      hasSelectAll={hasSelectAll}
      disabled={disabled}
      ClearIcon={<CustomClearIcon />}
      ClearSelectedIcon={<CustomClearIcon />}
      ArrowRenderer={CustomArrow}
      isLoading={loading}
      ItemRenderer={(props) =>
        formatShareOptionLabel({
          ...props,
          context:
            page === CUSTOMER_VIEW_SHARE_IB
              ? 'role'
              : page === VIEW.trends
              ? VIEW.trends
              : '',
        })
      }
    />
  );
};

export default MultiSelectDropdown;
