import React from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { logoutSSO } from 'store/home/home-my-view-actions';

const SESSION_IDEL_MINUTES = process.env.IDLE_SESSION_TIME_OUT || 30;

const AutoTimeOut = (props) => {
  const { id } = useSelector((state) => state.Auth.userClaimObject);
  const { ComposedClass } = props;

  const navigate = useNavigate();

  const handleOnIdle = async () => {
    if (id !== undefined && id !== '') {
      try {
        await logoutSSO(id);
      } catch (error) {
        console.log(error);
      } finally {
        navigate('/logout');
      }
    }
  };

  useIdleTimer({
    timeout: 1000 * 60 * SESSION_IDEL_MINUTES,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return <ComposedClass />;
};

export default AutoTimeOut;
