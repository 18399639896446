/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import SelectWithPagination from 'components/shared/dropdown-with-pagination/SelectWithPagination';
import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

import ConfigurableTable from 'components/shared/table/ConfigurableTable';
import classes from './SitePerformance.module.scss';
import {
  setExcelColumnRestiction,
  setFilterInit,
  setInsightType,
  setIsAccountableProduct,
  setProducts,
  setSelectedCustomer,
  setSelectedSite,
  setSeletedView,
  setSiteExpression,
  setSiteLoader,
} from 'store/customer/customerViewSlice';
import {
  SITE_PERFOMANCE_COLUMNS,
  SITE_PERFORMANCE_EXPRESSION,
  SITE_PERFORMANCE_FILTER_NAME,
  TIME_RANGE_EXP,
  CHECKBOXCLASS,
  TABLE_NAME_SITE_PERFORMANCE,
  CONTRACT_SEGMENT,
  FLAG
} from 'constants/constants';
import {
  exportData,
  getSitePerformanceData,
} from 'store/customer/customer-view-actions';
import {
  excludeColumnFromExcel,
  getCustomerExpr,
  getRestrictedColumn,
  isArray,
} from 'utils/utils';
import { SITE_PERF_APP_ID } from 'constants/constants';
import { getQlikSenseData } from 'services/qlik.service';
import { setSitePerformanceSquad } from 'store/customer/customerViewSlice';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { setEngagementDateInit } from 'store/customer/filter/filter-slice';
import GNESingleSelect from 'components/shared/dropdown/GNESingleSelect';

const SitePerformance = (props) => {
  const isAccountableProduct = useSelector(
    (state) => state?.CustomerView?.sitePerformanceFilters?.IsAccountableProduct
  );
  const selectedSquad = useSelector(
    (state) =>
      state?.CustomerView?.sitePerformanceFilters?.SiteSquad?.selectedValue
  );
  const selectedEcosystem = useSelector(
    (state) =>
      state?.CustomerView?.sitePerformanceFilters?.Ecosystem?.selectedValue
  );
  const selectedContractSegment = useSelector(
    (state) =>
      state?.CustomerView?.sitePerformanceFilters?.contract_segment_Filter
        ?.selectedValue
  );
  // eslint-disable-next-line no-unused-vars
  const [showMarketShare, setShowMarketShare] = useState(true);

  const sitePerformanceData = useSelector(
    (state) => state?.CustomerView?.sitePerformanceData
  );

  const isLoading = useSelector((state) => state?.CustomerView?.siteLoader);
  const isExporting = useSelector((state) => state?.CustomerView?.exportSite);
  useEffect(() => {
    setSearchedData(sitePerformanceData?.values);
  }, [sitePerformanceData]);

  const [searchedData, setSearchedData] = useState(sitePerformanceData?.values);

  const dispatch = useDispatch();

  const qlikToken = localStorage.getItem('qlikToken');
  const qlikCookie = localStorage.getItem('qlikCookie');
  const columnRestriction = useSelector(
    (state) => state?.CustomerView?.columnsRestiction
  );

  useEffect(() => {
    let excelColumnName = excludeColumnFromExcel(columnRestriction);
    dispatch(setExcelColumnRestiction(excelColumnName));
  }, [columnRestriction]);

  const selectedCustomer = useSelector(
    (state) => state?.CustomerView?.selectedCustomer
  );
  const selectedProducts = useSelector(
    (state) =>
      state?.CustomerView?.sitePerformanceFilters?.Product?.selectedValue
  );
  const filteredColumns = showMarketShare
    ? SITE_PERFOMANCE_COLUMNS
    : SITE_PERFOMANCE_COLUMNS.filter((column) => column.key !== 'marketShare');

  const selectedSites = useSelector(
    (state) =>
      state?.CustomerView?.sitePerformanceFilters
        ?.Site_Performance_SiteName_Address?.selectedValue
  );
  const sitePerformanceFilters = useSelector(
    (state) => state?.CustomerView?.sitePerformanceFilters
  );
  const productOptions = useSelector(
    (state) => state?.CustomerView?.sitePerformanceFilters?.Product
  );
  const ecosystemOptions = useSelector(
    (state) => state?.CustomerView?.sitePerformanceFilters?.Ecosystem
  );
  const squadOptions = useSelector(
    (state) => state?.CustomerView?.sitePerformanceFilters?.Squad
  );
  const siteOptions = useSelector(
    (state) => state?.CustomerView?.sitePerformanceFilters?.Site
  );
  const contractsegmentOptions = useSelector(
    (state) =>
      state?.CustomerView?.sitePerformanceFilters?.contract_segment_Filter
  );
  const [timeRange, setTimeRange] = useState('');
  const defaultTimePeriod = {
    value: 'R12',
    label: 'R12M',
  };
  const navigation = useNavigate();
  const [selectedTimePeriod, setselectedTimePeriod] =
    useState(defaultTimePeriod);

  useEffect(() => {
    if (!Array.isArray(selectedCustomer)) {
      dispatch(setIsAccountableProduct(true));
      setselectedTimePeriod(defaultTimePeriod);
    }
  }, [selectedCustomer]);
  useEffect(() => {
    if (selectedSquad?.length > 1)
      dispatch(setSitePerformanceSquad({ options: [], selectedValue: [] }));
  }, [selectedProducts]);

  useEffect(() => {
    getSitePerformanceTimeRange();
  }, [selectedTimePeriod]);

  const siteExpressionExport = useSelector(
    (state) => state?.CustomerView?.siteExpression
  );
  let siteFilterExpression;
  useEffect(() => {
    if (!Array.isArray(selectedCustomer)) buildExpression();
  }, [
    selectedCustomer,
    selectedProducts,
    selectedSites,
    selectedTimePeriod,
    isAccountableProduct,
    selectedSquad,
    selectedEcosystem,
    selectedContractSegment,
  ]);

  const getSitePerformanceTimeRange = async () => {
    const response = await getQlikSenseData(
      TIME_RANGE_EXP.get(selectedTimePeriod?.value),
      null,
      SITE_PERFORMANCE_EXPRESSION.appId
    );
    setTimeRange(response.data.data.category[0].replaceAll("'", ' '));
  };

  const setSiteOnSelection = (value) => {
    dispatch(setSelectedSite(value));
  };

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [menuIsOpen, setMenuIsOpen] = useState({
    Ecosystem: false,
    Site_Performance_SiteName_Address: false,
    SiteSquad: false,
    Product: false,
    contract_segment_Filter: false,
  });
  const totalRecords =
    searchedData?.length >= 0 && searchedData?.length < 100
      ? searchedData?.length
      : 100;

  const [total, setTotal] = useState(totalRecords);
  useEffect(() => {
    setTotal(totalRecords);
  }, [totalRecords]);

  const pagination = {
    total: total,
    onChange: (page, size) => {
      setPageIndex(page);
      setPageSize(size);
    },
    pageSizeOptions: [10, 20, 30],
    showSizeChanger: true,
  };

  useEffect(() => {
    setPageIndex(pageIndex);
    const isLastPage = pageIndex === Math.ceil(total / pageSize);
    buildExpression();
    const selectedPageIndex =
      pageIndex > 0 && Math.ceil((pageIndex * pageSize) / 100);
    if (isLastPage && total < sitePerformanceData.totalSize) {
      dispatch(
        getSitePerformanceData(
          siteFilterExpression,
          selectedPageIndex,
          qlikCookie,
          qlikToken
        )
      );
      setTotal(total + 100);
    }
  }, [pageIndex]);
  const timePeriodOptions = [
    { value: 'R3', label: 'R3M' },
    { value: 'R6', label: 'R6M' },
    { value: 'R12', label: 'R12M' },
    { value: 'YTD', label: 'YTD' },
  ];

  const buildExpression = () => {
    const isAllProd =
      isArray(productOptions) &&
      productOptions?.length === selectedProducts?.length;

    const isAllSite =
      isArray(siteOptions) && siteOptions?.length === selectedSites?.length;

    const isAllSquad =
      isArray(squadOptions) && squadOptions?.length === selectedSquad?.length;

    const isAllEcosystem =
      isArray(ecosystemOptions) &&
      ecosystemOptions?.length === selectedEcosystem?.length;

    const allContractSegment =
      isArray(contractsegmentOptions) &&
      contractsegmentOptions?.length === selectedContractSegment?.length;

    const ecosystem = isAllEcosystem ? 'Ecosystem=' : selectedEcosystem;
    const squad = isAllSquad ? 'Squad=' : selectedSquad;

    const products = isAllProd ? 'Product=' : selectedProducts;

    const sites = isAllSite ? 'Site=' : selectedSites;

    const contractsegment = allContractSegment
      ? 'contract_segment_Filter='
      : selectedContractSegment;

    const accountExp = getCustomerExpr(
      selectedCustomer,
      isArray(selectedCustomer)
    );

    // build product expression
    const productExp = isArray(products)
      ? 'Product={"' + products.map((a) => a.label).join('","') + '"}'
      : '';

    // build business site expression
    const siteExp = isArray(sites)
      ? 'Site_ID={"' + sites.map((a) => a.value).join('","') + '"}'
      : '';
    //build Ecosystem  expression
    const ecosystemExp = isArray(ecosystem)
      ? 'Ecosystem={"' + ecosystem.map((a) => a.label).join('","') + '"}'
      : '';

    const squadExp = isArray(squad)
      ? 'Squad={"' + squad.map((a) => a.label).join('","') + '"}'
      : '';
    const contractsengmentExp = isArray(contractsegment)
      ? 'contract_segment_Filter={"' +
        contractsegment.map((a) => a.label).join('","') +
        '"}'
      : '';
    siteFilterExpression = `${accountExp}`;
    if (productExp !== '')
      siteFilterExpression = siteFilterExpression.concat(`, ${productExp}`);
    if (siteExp !== '')
      siteFilterExpression = siteFilterExpression.concat(`, ${siteExp}`);
    if (ecosystemExp !== '')
      siteFilterExpression = siteFilterExpression.concat(`, ${ecosystemExp}`);
    if (squadExp !== '')
      siteFilterExpression = siteFilterExpression.concat(`, ${squadExp}`);
    if (contractsengmentExp !== '')
      siteFilterExpression = siteFilterExpression.concat(
        `, ${contractsengmentExp}`
      );
    if (isAccountableProduct !== undefined && isAccountableProduct === true)
      siteFilterExpression = siteFilterExpression.concat(
        ', "isAccountableProduct"={"Y"}'
      );
    dispatch(setSiteExpression(siteFilterExpression));
  };

  useEffect(() => {
    if (!selectedCustomer?.label) {
      dispatch(setSiteLoader(false));
      setSearchedData([]);
      return;
    }
    buildExpression();
    dispatch(
      getSitePerformanceData(
        siteFilterExpression,
        0,
        qlikCookie,
        qlikToken,
        selectedTimePeriod,
        'grossSales',
        'descend'
      )
    );
  }, [
    selectedCustomer,
    selectedProducts,
    selectedSites,
    selectedTimePeriod,
    selectedEcosystem,
    selectedSquad,
    selectedContractSegment,
    isAccountableProduct,
  ]);

  const handleClick = (rowData, index) => {
    const payload = {
      value: rowData?.mdmId,
      label: rowData?.siteName,
      zip: rowData?.zip,
      flag: FLAG.site[0],
    };
    dispatch(setFilterInit());
    dispatch(setEngagementDateInit());
    dispatch(setSeletedView('site'));
    dispatch(setSelectedCustomer(payload));
    dispatch(setInsightType('general'));
    navigation(`/customerView/list/${rowData?.siteName}`);
  };
  const handleSorting = (column, order) => {
    buildExpression();

    dispatch(
      getSitePerformanceData(
        siteFilterExpression,
        0,
        qlikCookie,
        qlikToken,
        selectedTimePeriod,
        column,
        order
      )
    );
  };
  const handleAccountProdCheckbox = (e) => {
    dispatch(setIsAccountableProduct(e?.target?.checked));
    dispatch(setProducts({ options: [], selectedValue: [] }));
    dispatch(setSitePerformanceSquad({ options: [], selectedValue: [] }));
  };

  const onMenuOpen = useCallback((filterName, value) => {
    let menuIsOpenLocal = menuIsOpen;
    if (filterName !== '' && value) {
      menuIsOpenLocal = Object.keys(menuIsOpen).reduce((acc, key) => {
        return { ...acc, [key]: filterName === key };
      }, {});
    } else if ((filterName !== '' && !value) || filterName === '') {
      menuIsOpenLocal = Object.keys(menuIsOpen).reduce((acc, key) => {
        return { ...acc, [key]: false };
      }, {});
    }
    setMenuIsOpen({ ...menuIsOpenLocal });
  }, []);
  const downloadData = () => {
    dispatch(
      exportData(
        TABLE_NAME_SITE_PERFORMANCE,
        selectedCustomer?.value,
        qlikCookie,
        qlikToken,
        siteExpressionExport,
        selectedTimePeriod,
        '',
        '',
        excludeColumnFromExcel(columnRestriction)
      )
    );
  };
  const exportTooltip = (
    <Tooltip bsclass='gne-tooltip tooltip--left'>Send to Excel</Tooltip>
  );
  const size = { x: 1550, y: 400 };

  return (
    <div className='full-width-in-container light-grey'>
      <div
        id='SitePerformance'
        className='pt-3'>
        <Row>
          <Col
            className='pt-3'
            md={6}>
            <p className='title title__bold title__large mb-0'>
              Site Performance
            </p>
            <p className='title__small pt-0 text text__grey'>{timeRange}</p>
          </Col>
          <Col
            className='d-flex justify-content-end'
            md={6}>
            <OverlayTrigger
              placement='top'
              overlay={exportTooltip}>
              <i
                onClick={downloadData}
                className={`icon icon__affiliations_export_excel1 mt-3`}
              />
            </OverlayTrigger>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <label className='title__x-small'>Time Period</label>
            <GNESingleSelect
              value={selectedTimePeriod}
              onChange={(e) => setselectedTimePeriod(e)}
              options={timePeriodOptions}
              className='single-select'
            />
          </Col>
          <Col md={2}>
            <label className='title__x-small'>Ecosystem</label>
            <SelectWithPagination
              menuIsOpen={menuIsOpen}
              onMenuOpen={onMenuOpen}
              filter={sitePerformanceFilters}
              appid={SITE_PERF_APP_ID}
              fieldName='Ecosystem'
              isMulti={true}
              filterName={SITE_PERFORMANCE_FILTER_NAME}
              cacheUniqs={[selectedCustomer]}
            />
          </Col>
          <Col md={2}>
            <label className='title__x-small'>Site</label>
            <SelectWithPagination
              menuIsOpen={menuIsOpen}
              onMenuOpen={onMenuOpen}
              filter={sitePerformanceFilters}
              fieldName='Site_Performance_SiteName_Address'
              appid={SITE_PERF_APP_ID}
              isMulti={true}
              onChange={setSiteOnSelection}
              filterName={SITE_PERFORMANCE_FILTER_NAME}
              cacheUniqs={[selectedCustomer]}
            />
          </Col>
          <Col md={2}>
            <label className='title__x-small'>Squad</label>
            <SelectWithPagination
              menuIsOpen={menuIsOpen}
              onMenuOpen={onMenuOpen}
              filter={sitePerformanceFilters}
              fieldName='SiteSquad'
              appid={SITE_PERF_APP_ID}
              isMulti={true}
              filterName={SITE_PERFORMANCE_FILTER_NAME}
              cacheUniqs={[
                selectedCustomer,
                isAccountableProduct,
                selectedProducts,
              ]}
            />
          </Col>

          <Col md={2}>
            <label className='title__x-small'>Products</label>
            <SelectWithPagination
              menuIsOpen={menuIsOpen}
              onMenuOpen={onMenuOpen}
              filter={sitePerformanceFilters}
              fieldName='Product'
              isMulti={true}
              appid={SITE_PERF_APP_ID}
              filterName={SITE_PERFORMANCE_FILTER_NAME}
              cacheUniqs={[
                selectedCustomer,
                isAccountableProduct,
                selectedSquad,
              ]}
            />
          </Col>
          {!excludeColumnFromExcel(columnRestriction).includes(
            CONTRACT_SEGMENT
          ) && (
            <Col md={2}>
              <label className='title__x-small'>Contract Segment</label>
              <SelectWithPagination
                menuIsOpen={menuIsOpen}
                onMenuOpen={onMenuOpen}
                filter={sitePerformanceFilters}
                fieldName='contract_segment_Filter'
                isMulti={true}
                appid={SITE_PERF_APP_ID}
                filterName={SITE_PERFORMANCE_FILTER_NAME}
                cacheUniqs={[selectedCustomer, isAccountableProduct]}
              />
            </Col>
          )}
          <Col md={3}>
            <label className='title__x-small w-100'></label>

            <span className='title__small'>
              <Form.Check
                inline
                type='checkbox'
                label='Accountable Products'
                checked={isAccountableProduct}
                onChange={handleAccountProdCheckbox}
                className={isAccountableProduct && CHECKBOXCLASS}
              />
            </span>
          </Col>
        </Row>
        &nbsp;
      </div>

      <Row
        id='SitePerformance'
        className={classes.sitePerformanceContainer}>
        <Col xs={12}>
          <Spin spinning={isLoading || isExporting}>
            <ConfigurableTable
              rows={searchedData}
              columns={getRestrictedColumn(
                TABLE_NAME_SITE_PERFORMANCE,
                filteredColumns,
                columnRestriction
              ).map((col) => {
                if (col.dataIndex === 'siteName') {
                  return {
                    ...col,
                    render: (text, record) => (
                      <span
                        className='span-link'
                        onClick={() => handleClick(record)}>
                        {text}
                      </span>
                    ),
                  };
                }
                return col;
              })}
              expandRowByClick={false}
              enableColumnSelection={false}
              enableExport={false}
              exportFileName='sitePerformanceDetails'
              tableName={TABLE_NAME_SITE_PERFORMANCE}
              expression={siteFilterExpression}
              isGlobalExport={true}
              pagination={pagination}
              isGlobalSort={true}
              onSort={handleSorting}
              timePeriod={selectedTimePeriod}
              dimentions={size}
            />
          </Spin>
        </Col>
      </Row>
    </div>
  );
};

export default SitePerformance;
