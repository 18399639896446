import LinkButton from 'components/shared/button/LinkButton';
import GNEAsyncPagination from 'components/shared/dropdown-with-pagination/GNEAsyncPagination';
import GNESingleSelect from 'components/shared/dropdown/GNESingleSelect';
import {
  ALL,
  CHECKBOXCLASS,
  CONFIGURE_VIEW,
  PRODUCT_CONFIG,
  VIEW,
} from 'constants/constants';
import { Button, Col, Form, Row } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import { wrapMenuList } from 'react-select-async-paginate';
import './KpiMapping.scss';

import { setSelectedCustomer } from 'store/customer/customerViewSlice';
import './KpiMapping.scss';

import { GetProductAPI, GetSquatAPI } from 'store/trends_v2/trendsAction';
import { trendsInitaiState } from 'store/trends_v2/trendsInitailState';
import {
  setDynamicFilter,
  setLocalFiltersData,
  setProductOptions,
  setSelectedProduct,
} from 'store/trends_v2/trendsSlice';
import {
  ConvertSingleMultiSelectValue,
  dropdownOptions,
  ValueContainer,
} from 'utils/utils';
import LocalFilters from './LocalFilters';
import { useEffect, useState } from 'react';

const KPIFilters = (props) => {
  const {
    page,
    view,
    enableApplyDisableAddtoButton,
    promiseInProgress,
    buildExpression,
    accountableProductsChecked,
    handleProductType,
  } = props;

  const dispatch = useDispatch();

  const { unixId, roleId } = useSelector(
    (state) => state.Auth?.userClaimObject
  );
  const userRestrictedRole = useSelector(
    (state) => state?.Auth?.userRestriction
  );
  const {
    selectedKPI,
    selectedDimension,
    KPIFilter,
    localFiltersData,
    defaultTimePeriod,
    advanceButtonValue,
  } = useSelector((state) => state?.trends);
  const { value: selectedSquad } = useSelector(
    (state) => state.trends.KPIFilter.Squad
  );

  const { value: selectedEcosystem } = useSelector(
    (state) => state.trends.KPIFilter.Ecosystem
  );

  const { value: selectedProduct } = useSelector(
    (state) => state.trends.KPIFilter.Product
  );

  const { value: selectedTimePeriod } = useSelector(
    (state) => state.trends.KPIFilter.TimePeriod
  );

  const focusAccountChecked = useSelector(
    (state) => state?.CustomerView?.focusAccountChecked
  );
  const [disableReset, setDisableReset] = useState(
    view === 'edit' ? false : true
  );

  const [previousSelectedKPI, setPreviousSelectedKPI] = useState('');
  const areAllValuesEmpty = (obj) => {
    return Object.values(obj).every(
      (item) => Array.isArray(item.value) && item.value.length === 0
    );
  };
  useEffect(() => {
    if (
      selectedProduct?.length === 0 &&
      selectedEcosystem?.length === 0 &&
      selectedSquad?.length === 0 &&
      JSON.stringify(selectedTimePeriod) ===
        JSON.stringify(defaultTimePeriod) &&
      accountableProductsChecked &&
      (Object.keys(localFiltersData).length === 0 ||
        areAllValuesEmpty(localFiltersData))
    ) {
      setDisableReset(true);
    } else {
      setDisableReset(false);
    }
  }, [
    focusAccountChecked,
    selectedDimension,
    selectedEcosystem,
    selectedProduct,
    selectedSquad,
    selectedTimePeriod,
    defaultTimePeriod,
    accountableProductsChecked,
    localFiltersData,
  ]);
  useEffect(() => {
    setPreviousSelectedKPI(selectedKPI?.productConfig);
    productForMarketKPI();
  }, [selectedKPI]);
  const handleReset = () => {
    dispatch(setLocalFiltersData(trendsInitaiState.localFiltersData));
    let resetObj = {};

    for (let [key] of Object.entries(localFiltersData)) {
      resetObj[key] = { ...localFiltersData[key], value: [] };
    }
    dispatch(setLocalFiltersData(resetObj));
    dispatch(
      setDynamicFilter({
        ...KPIFilter,
        Ecosystem: { ...KPIFilter.Ecosystem, value: [] },
        Product: { ...KPIFilter.Product, value: [] },
        Squad: { ...KPIFilter.Squad, value: [] },
        TimePeriod: { ...KPIFilter.TimePeriod, value: defaultTimePeriod },
      })
    );
    handleProductType({ target: { checked: true } });
    setDisableReset(true);
  };

  const handleApply = () => {
    buildExpression();
  };

  const handleEcosystemChange = (value) => {
    const selectedValue = ConvertSingleMultiSelectValue(value);
    dispatch(
      setDynamicFilter({
        ...KPIFilter,
        Ecosystem: { ...KPIFilter.Ecosystem, value: selectedValue },
      })
    );
    // Reset Customer on ecosystem change
    if (page !== CONFIGURE_VIEW.insights) {
      dispatch(setSelectedCustomer([]));
    }
    setDisableReset(false);
  };

  const handleProductChange = (value) => {
    const selectedValue = ConvertSingleMultiSelectValue(value);
    dispatch(
      setDynamicFilter({
        ...KPIFilter,
        Product: { ...KPIFilter.Product, value: selectedValue },
      })
    );
    setDisableReset(false);
  };

  const handleSquadChange = (value) => {
    const selectedValue = ConvertSingleMultiSelectValue(value);
    dispatch(
      setDynamicFilter({
        ...KPIFilter,
        Squad: { ...KPIFilter.Squad, value: selectedValue },
      })
    );
    setDisableReset(false);
  };

  const handleTimePeriodChange = (value) => {
    dispatch(
      setDynamicFilter({
        ...KPIFilter,
        TimePeriod: { ...KPIFilter.TimePeriod, value: value },
      })
    );
    setDisableReset(false);
  };

  const loadEcosystemOptions = async (searchValue) => {
    let options = KPIFilter?.Ecosystem.options;
    if (searchValue) {
      options = KPIFilter?.Ecosystem.options?.filter((item) => {
        return item.label.toLowerCase()?.includes(searchValue?.toLowerCase());
      });
    }
    return {
      options: options,
      hasMore: false,
    };
  };

  const loadSquadOptions = async (searchValue) => {
    if (searchValue && searchValue.length <= 2) {
      return { options: [], hasMore: false };
    }
    let response = { options: [], hasMore: false };
    if (page === CONFIGURE_VIEW.insights) {
      response = await GetSquatAPI(searchValue, KPIFilter?.Product.value);
    } else {
      response = await GetSquatAPI(
        searchValue,
        KPIFilter?.Product.value,
        userRestrictedRole,
        unixId,
        roleId
      );
    }

    let selectedSquad = KPIFilter?.Squad?.value;
    if (KPIFilter?.Product.value?.length > 0 && selectedSquad?.length !== 0) {
      // Product  always has signle Squad that's why binding options in selected squad
      // Squad can have multiple Product
      selectedSquad = response?.options;
    }

    dispatch(
      setDynamicFilter({
        ...KPIFilter,
        Squad: {
          ...KPIFilter.Squad,
          // Need to updated Squad if product is changed
          value: selectedSquad,
          options: response.options,
        },
      })
    );
    return {
      options: response.options,
      hasMore: response.hasMore,
    };
  };

  const loadProductOptions = async (searchValue) => {
    if (searchValue && searchValue.length <= 2) {
      return { options: [], hasMore: false };
    }
    let response = { options: [], hasMore: false };
    if (page === CONFIGURE_VIEW.insights) {
      response = await GetProductAPI(searchValue, KPIFilter?.Squad.value);
    } else {
      response = await GetProductAPI(
        searchValue,
        KPIFilter?.Squad.value,
        userRestrictedRole,
        unixId,
        roleId
      );
    }

    const accountableProductOptions = response?.options?.filter(
      (product) => product?.priorityFlag === 'Y'
    );

    const allProductOptions = response.options;

    if (accountableProductsChecked && KPIFilter?.Product?.value?.length > 0) {
      const selectedAccountableProducts = KPIFilter?.Product?.value?.filter(
        (selection) =>
          accountableProductOptions?.find(
            (item) => item.label === selection.label
          )
      );

      dispatch(setSelectedProduct(selectedAccountableProducts));
    }

    let allProducts = accountableProductsChecked
      ? accountableProductOptions
      : allProductOptions;

    if (
      selectedKPI?.productConfig &&
      PRODUCT_CONFIG.GENERAL !== selectedKPI?.productConfig
    ) {
      allProducts = allProducts?.filter((item) => item.marketShare === 'Y');
    }

    dispatch(setProductOptions(allProducts));

    return {
      options: allProducts,
      hasMore: response.hasMore,
    };
  };

  const shouldLoadMoreOptions = (scrollHeight, clientHeight, scrollTop) => {
    const bottomBorder = (scrollHeight - clientHeight) / 2;
    const loadMore = bottomBorder < scrollTop;
    // shouldLoadMore.current = loadMore;
    return loadMore;
  };

  const isEcosystemDisabled =
    page === CONFIGURE_VIEW.insights
      ? false
      : focusAccountChecked ||
        selectedDimension?.filterEnable?.ecosystemEnable === 'N';

  const productForMarketKPI = () => {
    if (
      selectedKPI?.productConfig !== PRODUCT_CONFIG.GENERAL &&
      KPIFilter?.Product?.value?.length > 0
    ) {
      if (previousSelectedKPI !== selectedKPI?.productConfig) {
        dispatch(setSelectedProduct([]));
      }
    }
  };

  return (
    <div className='w-100 pt-3 pb-3 filter-container-scroll'>
      <Row style={{ position: 'relative' }}>
        <Col
          sm={12}
          className='p-0 pb-2'>
          <div className='d-flex justify-content-between mb-2'>
            <div className='d-flex align-items-center filter-icon-container'>
              <span className='text text__bold badge-circle'>4</span>{' '}
              <span className='text text__bold text__blue'>Filters</span>
            </div>
            <LinkButton
              className={`text text__x-small ${disableReset ? 'disabled' : ''}`}
              onClick={handleReset}>
              {disableReset ? (
                <i className='icon icon__reset-disabled'></i>
              ) : (
                <i className='icon icon__reset'></i>
              )}
              Reset
            </LinkButton>
          </div>
        </Col>
        <div
          className={
            !advanceButtonValue ? `filter-container` : `filter-maxconatiner`
          }>
          <Col
            sm={12}
            className='p-0'>
            <label
              className={`title__x-small ${
                isEcosystemDisabled ? 'title__grey' : ''
              }`}>
              Ecosystem
            </label>

            <GNEAsyncPagination
              components={{
                ValueContainer: ValueContainer,
                Option: wrapMenuList(dropdownOptions),
              }}
              value={KPIFilter?.Ecosystem.value}
              onChange={handleEcosystemChange}
              loadOptions={loadEcosystemOptions}
              shouldLoadMore={shouldLoadMoreOptions}
              menuPosition='fixed'
              className={'custom-menu-portal'}
              searchPlaceholder='Search Ecosystem'
              placeholder={
                selectedDimension?.filterSingleSelect?.ecosystemSingleSelect ===
                'Y'
                  ? 'Select Ecosystem'
                  : ALL
              }
              closeMenuOnSelect={
                selectedDimension?.filterSingleSelect?.ecosystemSingleSelect ===
                'Y'
              }
              isClearable={
                selectedDimension?.filterSingleSelect?.ecosystemSingleSelect ===
                'N'
              }
              isMulti={
                selectedDimension?.filterSingleSelect?.ecosystemSingleSelect ===
                'N'
              }
              isDisabled={isEcosystemDisabled}
            />
          </Col>
          <Col
            sm={12}
            className='p-0'>
            <label className='title__x-small mt-2'>Squad</label>
            <GNEAsyncPagination
              components={{
                ValueContainer: ValueContainer,
                Option: wrapMenuList(dropdownOptions),
              }}
              value={KPIFilter?.Squad.value}
              loadOptions={loadSquadOptions}
              shouldLoadMore={shouldLoadMoreOptions}
              cacheUniqs={[
                accountableProductsChecked,
                KPIFilter?.Product.value,
              ]}
              onChange={handleSquadChange}
              menuPosition='fixed'
              className={'custom-menu-portal'}
              searchPlaceholder='Search Squad'
              placeholder={
                selectedDimension?.filterSingleSelect?.squadSingleSelect === 'Y'
                  ? 'Select Squad'
                  : ALL
              }
              closeMenuOnSelect={
                selectedDimension?.filterSingleSelect?.squadSingleSelect === 'Y'
              }
              isClearable={
                selectedDimension?.filterSingleSelect?.squadSingleSelect === 'N'
              }
              isMulti={
                selectedDimension?.filterSingleSelect?.squadSingleSelect === 'N'
              }
              isDisabled={selectedDimension?.filterEnable?.squadEnable === 'N'}
            />
          </Col>
          <Col
            sm={12}
            className='p-0 mt-2'>
            <span className='d-flex justify-content-between align-items-center mt-1'>
              <label className='title__x-small '>Product</label>
              <Form.Check
                inline
                type='checkbox'
                label='Accountable'
                checked={accountableProductsChecked}
                onChange={handleProductType}
                className={`title__x-small me-0 ${
                  accountableProductsChecked && CHECKBOXCLASS
                }`}
                disabled={
                  selectedDimension?.filterEnable?.productEnable === 'N'
                }
                reverse
              />
            </span>

            <GNEAsyncPagination
              components={{
                ValueContainer: ValueContainer,
                Option: wrapMenuList(dropdownOptions),
              }}
              value={KPIFilter?.Product.value}
              loadOptions={loadProductOptions}
              shouldLoadMore={shouldLoadMoreOptions}
              cacheUniqs={[
                accountableProductsChecked,
                KPIFilter?.Squad.value,
                selectedKPI?.productConfig,
              ]}
              onChange={handleProductChange}
              menuPosition='fixed'
              className={'custom-menu-portal'}
              searchPlaceholder='Search Product'
              placeholder={
                selectedDimension?.filterSingleSelect?.productSingleSelect ===
                'Y'
                  ? 'Select Product'
                  : ALL
              }
              closeMenuOnSelect={
                selectedDimension?.filterSingleSelect?.productSingleSelect ===
                'Y'
              }
              isClearable={
                selectedDimension?.filterSingleSelect?.productSingleSelect ===
                'N'
              }
              isMulti={
                selectedDimension?.filterSingleSelect?.productSingleSelect ===
                'N'
              }
              isDisabled={
                selectedDimension?.filterEnable?.productEnable === 'N'
              }
            />
          </Col>

          <Col
            sm={12}
            className='p-0'>
            <label className='title__x-small mt-2'>Time Period</label>
            <GNESingleSelect
              value={KPIFilter?.TimePeriod.value}
              onChange={handleTimePeriodChange}
              options={KPIFilter?.TimePeriod.options}
              className='single-select custom-menu-portal'
              menuPosition='fixed'
              searchPlaceholder='Search Time Period'
              isDisabled={
                selectedDimension?.filterEnable?.timePeriodEnable === 'N'
              }
            />
          </Col>
          <LocalFilters
            view={view}
            page={page}
            setDisableReset={setDisableReset}></LocalFilters>
        </div>
      </Row>

      <Button
        variant={`btn btn-gne btn-primary w-100 ${
          view === 'add' ? 'btn-gne__outline--add' : ''
        }`}
        onClick={handleApply}
        disabled={promiseInProgress || enableApplyDisableAddtoButton}
        className='mb-2 mt-2'>
        Apply
      </Button>
    </div>
  );
};
export default KPIFilters;
