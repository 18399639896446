import Select from 'react-select';
import ReactSelectSearch from 'utils/hoc/react-select-search';
import { CustomDropdownIndicator } from 'utils/utils';

export const ReactSelect = (props) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: state.isFocused ? '0 0 0 1px  #004677' : null,
      '&:hover': {
        borderColor: state.isFocused ? '#004677' : '#B3B3B3',
      },
    }),
  };
  return (
    <Select
      {...props}
      value={props.value}
      onChange={props.onChange}
      options={props.options}
      className={`${props.className}`}
      classNamePrefix='gne'
      placeholder={props.placeholder}
      formatOptionLabel={props.formatOptionLabel}
      menuPosition={props.menuPosition}
      isClearable={props.isClearable}
      components={{
        DropdownIndicator: CustomDropdownIndicator,
        ...props?.components,
      }}
      isDisabled={props.isDisabled}
      styles={customStyles}
    />
  );
};

const GNESingleSelect = ReactSelectSearch(ReactSelect);
export default GNESingleSelect;
