import { PING_FEDERATE_URL } from 'constants/constants';
import { Button } from 'react-bootstrap';
import { useEffect } from 'react';
import { resetHMVStore } from 'store/home/homeMyViewSlice';
import { resetAll } from 'store/auth/Authslice';
import { resetCVStore } from 'store/customer/customerViewSlice';
import { resetTrendStore } from 'store/trends_v2/trendsSlice';
import { useDispatch } from 'react-redux';
import classes from './LogoutModal.module.scss';

const LogoutModal = () => {
  const dispatch = useDispatch();

  //clearing the store on logout
  useEffect(() => {
    dispatch(resetHMVStore());
    dispatch(resetAll());
    dispatch(resetCVStore());
    dispatch(resetTrendStore([]));
    localStorage.clear();
  }, []);

  const navigateToHome = () => {
    window.location.href = PING_FEDERATE_URL;
  };
  return (
    <div className={`d-flex flex-column align-items-center bg-white`}>
      <i className='icon icon__logo animate__animated animate__rubberBand'></i>
      <div className='divider-horizontal divider__grey w-50 mt-1'></div>
      <div className={`${classes.messageContainer} mt-4 w-50`}>
        <p
          className={`${classes.subMessage} text__grey title__bold title__medium`}>
          You have been logged out.
        </p>
        <p className={`${classes.sessionMessage} mt-4 mb-4`}>
          Your session has expired. Click "Log in" to redirect to the login
          page.
        </p>
        <Button
          variant={`outline-primary w-25 btn-gne btn-gne__outline float-right`}
          onClick={navigateToHome}>
          <span>Log in</span>
        </Button>
      </div>
    </div>
  );
};

export default LogoutModal;
