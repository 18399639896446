import api from 'api';
import { setQlikAuth } from 'services/qlik.service';

export const isTokenExpired = (token) => {
  const decodedJWT = parseJWT(token);
  if (decodedJWT !== null && decodedJWT.exp * 1000 > Date.now()) {
    return false;
  } else {
    return true;
  }
};

export const parseJWT = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const isCookieExpired = async (unixId) => {
  var track = true;
  if (track && localStorage.getItem('qlikCookie')) {
    track = false;
    try {
      const response = await api.get(`qlik/validate-cookie`, {
        headers: {
          qlikCookie: localStorage.getItem('qlikCookie'),
          Authorization: `Bearer ${localStorage.getItem('qlikToken')}`,
        },
      });
      setQlikAuth(response?.data.token, response?.data.cookie);
      track = true;
      return true;
    } catch (error) {
      //will perform logout here
      return false;
    }
  } else {
    return true;
  }
};
