import { useRef, useState } from 'react';
import { useClickAway } from 'utils/hooks/useClickAway';

import {
  CustomDropdownIndicator,
  CustomMenuList,
  CustomMenuPortal,
  CustomValueContainer,
  handleInputChange,
} from 'utils/utils';

const ReactSelectSearch = (WrappedComponent) => {
  const WithSearch = (props) => {
    const [isFocused, setIsFocused] = useState(false);
    const [inputValue, setInputValue] = useState('');

    const containerRef = useRef();
    useClickAway(containerRef, () => {
      setInputValue('');
      setIsFocused(false);
    });

    return (
      <div
        ref={containerRef}
        className={props.containerCss}>
        <WrappedComponent
          {...props}
          onMenuOpen={() => {
            setInputValue('');
            props?.onMenuOpen && props?.onMenuOpen();
          }}
          onMenuClose={() => {
            setIsFocused(false);
            props?.onMenuClose && props?.onMenuClose();
          }}
          components={{
            DropdownIndicator: CustomDropdownIndicator,
            MenuPortal: CustomMenuPortal,
            MenuList: CustomMenuList,
            ValueContainer: CustomValueContainer,
            ...props?.components,
          }}
          inputValue={inputValue}
          isSearchable={false}
          onMenuInputFocus={() => setIsFocused(true)}
          menuPosition={props.menuPosition}
          onChange={(value) => {
            props.onChange(value);
            if (!props.isMulti) {
              setInputValue('');
              setIsFocused(false);
            }
          }}
          onInputChange={(value, action) => {
            props?.onDropdownAction && props?.onDropdownAction(action?.action);
            setInputValue(handleInputChange(value, action));
          }}
          // It is causing the default facus behaviour and creating issues while first time value selection in dropdown.
          // Need to find other solution for focusing the search box.
          onFocus={() => {
            if (!isFocused) {
              setTimeout(() => {
                document.querySelector('.search-box')?.focus();
              });
            }
          }}
          {...{
            menuIsOpen: isFocused || undefined,
            isFocused: isFocused || undefined,
          }}
        />
      </div>
    );
  };

  return WithSearch;
};
export default ReactSelectSearch;
