import React from 'react';

export function useClickAway(ref, cb) {
  const refCb = React.useRef(cb);

  React.useLayoutEffect(() => {
    refCb.current = cb;
  }, [cb]);

  React.useEffect(() => {
    const handler = (e) => {
      const element = ref.current;
      if (!element.contains(e.target)) {
        refCb.current(e);
      }
    };

    // Will fire on outsite click of tab/window
    const handleBlur = () => {
      refCb.current({ type: 'blur' });
    };

    // Pointerdown works for mousedown and touchstart
    document.addEventListener('pointerdown', handler);
    window.addEventListener('blur', handleBlur);

    return () => {
      document.removeEventListener('pointerdown', handler);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  return ref;
}
