import React, { useEffect, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import GNEModal from 'components/shared/modal/Modal';
import LinkButton from 'components/shared/button/LinkButton';
import classes from './CopyInsightBoard.module.scss';
import {
  setAdvanceSearchSelectedData,
  setSelectedCustomer,
} from 'store/customer/customerViewSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  copyInsightBoard,
  getAllInsightBoards,
} from 'store/customer/customer-view-actions';
import { toast } from 'react-toastify';
import _, { isEmpty } from 'lodash';
import CustomerDropdown from 'components/shared/customer/CustomerDropdown';
import { CONFIGURE_VIEW, CUSTOMER_TYPE_FLAG } from 'constants/constants';
import AdvanceSearch from 'components/shared/customer/AdvanceSearch';
import 'animate.css';

const CopyInsightBoard = ({ showModal, setShowModal, currentInsight }) => {
  const [selectedOption, setSelectedOption] = useState([]);
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    dispatch(setSelectedCustomer([]));
    setSelectedOption([]);
    setShowModal(false);
    dispatch(setAdvanceSearchSelectedData({}));
  };
  const { unixId, roleId } = useSelector(
    (state) => state?.Auth?.userClaimObject
  );
  const advanceSearchClicked = useSelector(
    (state) => state?.CustomerView?.advanceSearchClicked
  );

  const handleSave = async () => {
    let insightBoardFrom = {
      id: currentInsight.id,
      insightType: currentInsight.insightType,
      unixId: unixId,
      roleId: roleId,
      customerId: currentInsight.accountId,
    };
    let insightBoardTo = {
      customerId: selectedOption?.value,
      insightBoardName: selectedOption?.label,
    };

    const response = await copyInsightBoard(insightBoardFrom, insightBoardTo);

    if (response?.message.includes('success')) {
      toast.success(response?.message);
      dispatch(getAllInsightBoards(unixId, roleId));
      dispatch(setAdvanceSearchSelectedData({}));
    } else toast.warn(response?.message);
    dispatch(setSelectedCustomer([]));
    setShowModal(false);
  };

  const onDropdownSelection = async (value) => {
    dispatch(setSelectedCustomer(value));
    setSelectedOption(value);
  };

  useEffect(() => {
    if (showModal) {
      dispatch(
        setAdvanceSearchSelectedData({
          customerType: [
            {
              value: `${currentInsight.flag}`,
              label: `${currentInsight.boardType}`,
            },
          ],
        })
      );
    }
    // eslint-disable-next-line
  }, [showModal]);
  const content = (
    <div className={`w-100 ${classes['content-container']}`}>
      <div className='d-flex align-items-center'>
        <i
          className={`icon icon__copy-insight-popup ${classes['remove-icon-wrap']}`}></i>
        <span className={classes['title-wrap']}>Copy Insight Board</span>
      </div>
      <div className={classes['subtitle-wrap']}>
        Create a new insight board with existing preferences.
      </div>

      <div className={`d-flex ${classes['dropdown-wrap']}`}>
        <span className={`mt-3 ${classes['dropdown-title']}`}>
          From {_.startCase(currentInsight?.boardType)}
        </span>
      </div>
      <div className={`d-flex mb-2 ${classes['from-title-wrap']}`}>
        {_.startCase(_.toLower(currentInsight.accountName))}
      </div>
      <div className={classes['dropdown-wrap']}>
        <div className={`d-flex ${classes['dropdown-title']}`}>
          To {_.startCase(currentInsight?.boardType)}
        </div>
        <CustomerDropdown
          page={CONFIGURE_VIEW.copyInsight}
          onChangeHandler={onDropdownSelection}
          currentInsightflag={CUSTOMER_TYPE_FLAG.get(currentInsight.boardType)}
          focusDisabled={false}
          focusChecked={true}
          isDisabled={false}
        />
      </div>
      {advanceSearchClicked[CONFIGURE_VIEW.copyInsight] && (
        <Row className='animate__animated animate__fadeIn search-container addCustomerView w-100'>
          <AdvanceSearch
            page={CONFIGURE_VIEW.copyInsight}
            viewType={CONFIGURE_VIEW.copyInsight}
          />
        </Row>
      )}
      <div
        className={`d-flex justify-content-end  ${classes['footer-align']} ${classes['content-foot']}`}>
        <LinkButton
          className='text text__small'
          onClick={handleCloseModal}>
          Cancel
        </LinkButton>
        <Button
          variant='btn btn-gne btn-primary'
          onClick={handleSave}
          className={`text__small ms-3 ${classes['save-btn']}`}
          disabled={isEmpty(selectedOption)}>
          Create
        </Button>
      </div>
    </div>
  );

  return (
    <GNEModal
      show={showModal}
      onHide={handleCloseModal}
      content={content}
      css='size-medium-lg'
      backdrop='static'
    />
  );
};

export default CopyInsightBoard;
