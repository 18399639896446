import { Modal, Container } from 'react-bootstrap';
import React from 'react';
import uuid from 'react-native-uuid';

const GNEModal = (props) => {
  return (
    <Modal
      {...props}
      key={props?.show}
      size={props.size}
      className={`gne-modal ${props.css}`}
      centered>
      <Modal.Header
        closeButton
        className={`modal-header border-0 animate__animated animate__fadeIn`}>
        <Modal.Title className='title__bold'>{props.titlecontent}</Modal.Title>
      </Modal.Header>

      <Modal.Body className='p-0 m-0'>
        <Container className='gne-container animate__animated animate__fadeIn'>
          {props.content}
        </Container>
      </Modal.Body>

      {props.footer && (
        <Modal.Footer className='mt-3'>
          <Container className='d-flex'>{props.footer}</Container>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default GNEModal;
