import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { components } from 'react-select';

import composeRefs from '@seznam/compose-react-refs';
import {
  ACTION_ADD_TO_MY_VIEW,
  ACTION_COPY,
  BENCHMARK_LEGEND_FIRST,
  COMPARE_BENCHMARK,
  COMPARE_KPI,
  COPY_TITLE_PREFIX,
  CUSTOMER_TYPE,
  DEFAULT_DATE_FORMAT,
  ENGAGEMENTS,
  EXPRESSION_PLACEHOLDER_TEXT,
  FILTER_DATE_FORMAT,
  VIEW,
} from 'constants/constants';
import { saveAs } from 'file-saver';
import { capitalize } from 'lodash';
import { Form } from 'react-bootstrap';
import {
  setAccountBookOfBusiness,
  setAccountEcosystem,
  setAccountParentPayer,
  setAccountPayer,
  setAccountProduct,
  setBookOfBusiness,
  setBrand,
  setChannelFilters,
  setConfigInsightSiteFilter,
  setConfigureInsightProducts,
  setConfigureInsightSquad,
  setContractSegmentFilter,
  setCopayAnalyticsEcosystem,
  setCopayAnalyticsProduct,
  setCopayAnalyticsSiteName,
  setCopayAnalyticsSquad,
  setCopayAnalyticsTimePeriod,
  setDiscussionTopicFilters,
  setEcosystem,
  setEngagement,
  setEngagmentEcosystem,
  setHCPCity,
  setHCPName,
  setHCPSpeciality,
  setHCPStakeholderType,
  setHCPState,
  setHCPZip,
  setIndication,
  setKeyMessageFilters,
  setParentPayer,
  setPayer,
  setPresentationNameFilters,
  setProductMixProduct,
  setProductMixSquad,
  setProducts,
  setSiteAddress,
  setSiteHCPAddress,
  setSiteHCPName,
  setSiteHCPStakeholderType,
  setSiteHIN,
  setSiteName,
  setSitePerformanceSquad,
  setSites,
  setSiteSpecility,
  setSiteState,
  setSiteUtilizationAddress,
  setSiteUtilizationEcosystem,
  setSiteUtilizationSite,
  setSiteUtilizationSiteCity,
  setSiteUtilizationSiteState,
  setSquad,
  setStakeholderName,
  setStakeholderType,
  setStakeholderTypeFilters,
  setSurveyTypeFilters,
  setTemplateNameFilters,
  setUserName,
  setUserRole,
} from 'store/customer/customerViewSlice';
import {
  setChannelFilter,
  setDiscussionTopicFilter,
  setKeyMessageFilter,
  setPresentationNameFilter,
  setStakeholderTypeFilter,
  setSurveyTypeFilter,
  setTemplateNameFilter,
} from 'store/home/homeMyViewSlice';
import * as XLSX from 'xlsx';

// Format number to be USD using the locale, style, and currency.
export const USDollar = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const numberToShortNumFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });

  return item
    ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol
    : parseFloat(num).toFixed(digits);
};

export const isAllselected = (child, parent) => {
  return parent?.length !== 1 && child?.length === parent?.length ? [] : child;
};
export const createExpression = (
  selectedKPI,
  selectedValue,
  fieldName,
  qlikfield
) => {
  if (isArray(selectedValue)) {
    if (qlikfield?.includes(' ')) {
      return `[${qlikfield}]= {"${selectedValue
        .map((a) => a.label)
        .join('","')}"}`;
    } else {
      return `${qlikfield}= {"${selectedValue
        .map((a) => a.label)
        .join('","')}"}`;
    }
  }
  return undefined;
};

export const createDynamicExp = (filter, fieldName = '') => {
  if (!filter) return;
  let fieldExpression = '';
  for (let [key, value] of Object.entries(filter)) {
    if (value?.value?.length > 0 && fieldName !== key) {
      fieldExpression = fieldExpression.concat(
        ` ${key}= {"${value?.value
          .map((a) => (key === 'Product' ? a?.label : a?.value))
          .join('","')}"} ,`
      );
    }
  }
  fieldExpression = fieldExpression.substring(0, fieldExpression.length - 1);
  return fieldExpression;
};
export const editDynamicExp = (filterData, localFilters) => {
  if (!filterData) return;
  let fieldExpression = '';
  localFilters.forEach((filter) => {
    if (filterData['selected' + filter.filterName]) {
      if (filterData['selected' + filter.filterName]?.length > 0) {
        fieldExpression = fieldExpression.concat(
          ` ${filter.qExprClm}= {"${filterData['selected' + filter.filterName]
            .map((a) => a?.value)
            .join('","')}"} ,`
        );
      }
    }
  });

  fieldExpression = fieldExpression.substring(0, fieldExpression.length - 1);
  return fieldExpression;
};

export const createDynamicFieldExp = (
  filterData,
  fieldName = '',
  dependencies
) => {
  if (!filterData) return;
  let fieldExpression = '';
  try {
    for (let [key, value] of Object.entries(filterData)) {
      if (
        value?.value?.length > 0 &&
        fieldName !== key &&
        dependencies.length > 0 &&
        (dependencies.includes(key + '.value') ||
          dependencies.includes(key) ||
          dependencies.includes(
            key.replace('[', '').replace(' ', '').replace(']', '') + '.value'
          ))
      ) {
        fieldExpression = fieldExpression.concat(
          ` ${key}={"${value?.value
            .map((a) =>
              ['Product', 'Ecosystem'].includes(key) ? a?.label : a?.value
            )
            .join('","')}"} ,`
        );
      }
    }
    fieldExpression = fieldExpression.substring(0, fieldExpression.length - 1);
    return fieldExpression;
  } catch (err) {
    console.log(err);
  }
};

export const isArray = (obj) => {
  if (typeof obj !== 'undefined' && Array.isArray(obj) && obj?.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const formatLabel = (num, label) => {
  const regex = /([$#%])(?!.*[$#%])/;
  const matches = label?.match(regex);
  const symbol = matches?.length > 0 ? matches[1] : '';
  const isNegative = num < 0;
  const tempNumber = isNegative ? Math.abs(num) : num;
  let formattedNumber = numberToShortNumFormatter(tempNumber, 2);
  formattedNumber = isNegative ? `-${formattedNumber}` : formattedNumber;

  if (symbol === '$') {
    return '$' + formattedNumber;
  }
  if (symbol === '%') {
    return formattedNumber + '%';
  }
  if (symbol === '#') {
    return formattedNumber;
  }
  return formattedNumber;
};

export const formatLabelWithoutKPI = (num, symbol, chartType, screenType) => {
  const isNegative = num < 0;
  const tempNumber = isNegative ? Math.abs(num) : num;
  let formattedNumber = numberToShortNumFormatter(tempNumber, 2);
  formattedNumber = isNegative ? `-${formattedNumber}` : formattedNumber;

  if (chartType === 'contractPie') {
    return screenType === 'account'
      ? `$${formattedNumber}`
      : `Contracted: ${'$' + formattedNumber}`;
  }

  if (chartType === 'payerPie') {
    return formattedNumber + '%';
  }

  if (symbol === '#') {
    return formattedNumber;
  }

  return formattedNumber;
};

export const getArrayLabelValues = (arr) => {
  if (isArray(arr)) {
    const labels = arr.map((obj) => obj?.label);
    const result = labels.join(', ');
    return result;
  }
  return '';
};
export const getRestrictedSquads = (squads, accountableCheckBox) => {
  if (isArray(squads)) {
    const labels = squads
      .filter(({ squad, priorityFlag }) => {
        if (accountableCheckBox) {
          return priorityFlag === 'Y' ? squad : null;
        } else {
          return squad;
        }
      })
      .map(({ squad }) => squad);

    const uniqueLabels = labels
      .filter((squad, index) => labels.indexOf(squad) === index)
      .join(', ');

    return uniqueLabels;
  }
  return '';
};
export const getRestrictedProducts = (products, accountableCheckBox) => {
  if (products?.length !== 0) {
    const labels = products
      .filter(({ squad, priorityFlag }) => {
        if (accountableCheckBox) {
          return priorityFlag === 'Y' ? squad : null;
        } else {
          return squad;
        }
      })
      .map(({ product }) => product)
      .join(', ');
    return labels;
  }
  return '';
};

export const getArrayLabelValuesWithoutSpace = (arr) => {
  if (isArray(arr)) {
    const labels = arr.map((obj) => obj?.label);
    const result = labels.join(',');
    return result;
  }
  return '';
};

export const getArrayValues = (arr) => {
  if (isArray(arr)) {
    const labels = arr.map((obj) => obj?.value);
    const result = labels.join(',');
    return result;
  }
  return '';
};

export const roundToOne = (number) => {
  if (number) {
    const result = parseFloat(number).toFixed(0);
    return isNaN(result) ? '-' : result;
  }

  return '-';
};

export const isEmptyObject = (obj) => {
  return JSON.stringify(obj) === '{}';
};

export const exportToExcel = (dataSource, columns, filename) => {
  const data = dataSource.map((item) => Object.values(item));

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet([
    columns.map((column) => column.title),
    ...data,
  ]);

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const buffer = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });

  const blob = new Blob([buffer], {
    type: 'application/vnd.openxmlformats-officedocument.preadsheetml.sheet',
  });

  saveAs(blob, `${filename}.xlsx`);
};

export const getFieldName = (fieldName) => {
  if (fieldName === 'StakeholderName') {
    return '[Person Stakeholder Name]';
  } else if (fieldName === 'StakeholderType') {
    return '[Person Stakeholder Type]';
  } else if (fieldName === 'Site') {
    return '[Site Business Name]';
  } else if (fieldName === 'contract_segment_Filter') {
    return 'contract_segment_Filter';
  } else if (fieldName === 'Indication') {
    return '[Indication_Flatten]';
  } else if (fieldName === 'HCPName') {
    return '[HCP Name]';
  } else if (fieldName === 'Speciality') {
    return '[HCP Speciality]';
  } else if (fieldName === 'City') {
    return '[HCP City]';
  } else if (fieldName === 'State') {
    return '[HCP State]';
  } else if (fieldName === 'Zip') {
    return '[HCP Zip]';
  } else if (fieldName === 'HCPStakeholderType') {
    return '[Stakeholder_Type]';
  } else if (fieldName === 'SiteName_Address') {
    return 'SiteName_Address';
  } else if (fieldName === 'SiteState') {
    return '[Site State]';
  } else if (fieldName === 'SiteHIN') {
    return '[site_hin]';
  } else if (fieldName === 'SiteAddress') {
    return '[Site Address]';
  } else if (fieldName === 'HCPAddress') {
    return '[HCP Address]';
  } else if (fieldName === 'SiteSpeciality') {
    return '[HCP Speciality]';
  } else if (fieldName === 'HCPNames') {
    return '[HCP Name]';
  } else if (fieldName === 'HCPStakeholderTypes') {
    return '[Stakeholder_Type]';
  } else if (fieldName === 'SiteUtilization_Ecosystem') {
    return 'SiteUtilization_Ecosystem';
  } else if (fieldName === 'SiteUtilization_Site_Address') {
    return 'SiteUtilization_Site_Address';
  } else if (fieldName === 'GNE_User_Name') {
    return '[GNE User Name]';
  } else if (fieldName === 'GENE_User_Role') {
    return '[GENE User Role]';
  } else if (fieldName === 'Channel') {
    return 'Channel';
  } else if (fieldName === 'DiscussionTopic') {
    return '[Discussion Topic]';
  } else if (fieldName === 'PayerStakeholderType') {
    return '[Stakeholder Type]';
  } else if (fieldName === 'TemplateName') {
    return '[Template Name]';
  } else if (fieldName === 'SurveyType') {
    return '[Survey Type]';
  } else if (fieldName === 'PresentationName') {
    return '[Presentation Name]';
  } else if (fieldName === 'KeyMessage') {
    return '[Key Message]';
  } else if (fieldName === 'copaySite') {
    return 'copaySite';
  } else if (fieldName === 'Site_Performance_SiteName_Address') {
    return 'SiteName_Address';
  }
  return fieldName;
};

export const setEngagementData = (fieldName, filterName) => {
  if (filterName === 'ProductMix') {
    const fieldNameMapping = {
      Product: setProductMixProduct,
      SiteSquad: setProductMixSquad,
    };
    return fieldNameMapping[fieldName];
  } else if (filterName === 'configureInsights') {
    const fieldNameMapping = {
      Product: setConfigureInsightProducts,
      SiteSquad: setConfigureInsightSquad,
      SiteName_Address: setConfigInsightSiteFilter,
    };
    return fieldNameMapping[fieldName];
  } else if (filterName === 'AccountPayer') {
    const fieldNameMapping = {
      AccountPayerProduct: setAccountProduct,
      AccountPayerEcosystem: setAccountEcosystem,
      AccountPayerParentPayer: setAccountParentPayer,
      AccountPayerName: setAccountPayer,
      AccountPayerBookOfBusiness: setAccountBookOfBusiness,
    };
    return fieldNameMapping[fieldName];
  } else if (filterName === 'CopayAnalytics') {
    const fieldNameMapping = {
      copayEcosystem: setCopayAnalyticsEcosystem,
      copaySiteAddressName: setCopayAnalyticsSiteName,
      copayQuarter: setCopayAnalyticsTimePeriod,
      copayProduct: setCopayAnalyticsProduct,
      copaySquad: setCopayAnalyticsSquad,
    };
    return fieldNameMapping[fieldName];
  } else if (filterName === 'HomeMarketingFilters') {
    const fieldNameMapping = {
      KeyMessage: setKeyMessageFilter,
      PresentationName: setPresentationNameFilter,
      SurveyType: setSurveyTypeFilter,
      TemplateName: setTemplateNameFilter,
      PayerStakeholderType: setStakeholderTypeFilter,
      DiscussionTopic: setDiscussionTopicFilter,
      Channel: setChannelFilter,
    };
    return fieldNameMapping[fieldName];
  } else if (filterName === 'InsightMarketingFilters') {
    const fieldNameMapping = {
      KeyMessage: setKeyMessageFilters,
      PresentationName: setPresentationNameFilters,
      SurveyType: setSurveyTypeFilters,
      TemplateName: setTemplateNameFilters,
      PayerStakeholderType: setStakeholderTypeFilters,
      DiscussionTopic: setDiscussionTopicFilters,
      Channel: setChannelFilters,
    };
    return fieldNameMapping[fieldName];
  } else {
    const fieldNameMapping = {
      StakeholderName: setStakeholderName,
      StakeholderType: setStakeholderType,
      Engagement: setEngagement,
      Brand: setBrand,
      Indication: setIndication,
      GNE_User_Name: setUserName,
      GENE_User_Role: setUserRole,
      Squad: setSquad,
      Site_Performance_SiteName_Address: setSites,
      Product: setProducts,
      Ecosystem: setEcosystem,
      EngagementEcosystem: setEngagmentEcosystem,
      SiteSquad: setSitePerformanceSquad,
      Payer: setPayer,
      ParentPayer: setParentPayer,
      BookOfBusiness: setBookOfBusiness,
      HCPName: setHCPName,
      HCPStakeholderType: setHCPStakeholderType,
      Speciality: setHCPSpeciality,
      City: setHCPCity,
      State: setHCPState,
      Zip: setHCPZip,
      SiteName_Address: setSiteName,
      SiteState: setSiteState,
      SiteHIN: setSiteHIN,
      contract_segment_Filter: setContractSegmentFilter,
      SiteAddress: setSiteAddress,
      HCPStakeholderTypes: setSiteHCPStakeholderType,
      HCPNames: setSiteHCPName,
      HCPAddress: setSiteHCPAddress,
      SiteSpeciality: setSiteSpecility,
      SiteUtilization_Ecosystem: setSiteUtilizationEcosystem,
      SiteUtilization_SiteName_Address: setSiteUtilizationSite,
      SiteUtilization_Site_Address: setSiteUtilizationAddress,
      SiteUtilization_SiteCity: setSiteUtilizationSiteCity,
      SiteUtilization_SiteState: setSiteUtilizationSiteState,
    };
    return fieldNameMapping[fieldName];
  }
};
export const searchData = (rows, value) => {
  const filteredData = rows.filter((item) => {
    return Object.values(item).some((field) =>
      field?.toString()?.toLowerCase()?.includes(value?.toLowerCase())
    );
  });
  return filteredData;
};

export const getIconClass = (engagement) => {
  switch (engagement) {
    case ENGAGEMENTS.phone:
      return 'phone';
    case ENGAGEMENTS.email:
      return 'email';
    case ENGAGEMENTS.inPerson:
      return 'in-person';
    case ENGAGEMENTS.video:
      return 'video';
    case ENGAGEMENTS.houseEmail:
      return 'house-email';
    case ENGAGEMENTS.medicalInquiry:
      return 'medical-inquiry';
    case ENGAGEMENTS.surveySent:
      return 'sent';
    case ENGAGEMENTS.surveyResponse:
      return 'response';
    case ENGAGEMENTS.accessSolutions:
      return 'enrollment';
    case ENGAGEMENTS.message:
      return 'message';
    case ENGAGEMENTS.vae:
      return 'vae';
    case ENGAGEMENTS.pact:
      return 'pact';
    case ENGAGEMENTS.prpeNotices:
      return 'prp-enotice';
    default:
      break;
  }
};

export const buildEngagementExpression = (
  selectedEngagements,
  selectedSquad,
  selectedStakeholderName,
  selectedStakeholderType,
  selectedBrand,
  selectedIndication,
  startDate,
  selectedEndDate,
  selectedAccount,
  selectedUser,
  selectedRole,
  selectedEcosystem
) => {
  const engagements =
    selectedEngagements?.selectedValue?.length > 0
      ? ', Engagement={"' +
        selectedEngagements?.selectedValue?.map((a) => a?.label).join('","') +
        '"}'
      : '';

  const squad =
    selectedSquad?.selectedValue?.length > 0
      ? ', Squad={"' +
        selectedSquad?.selectedValue?.map((a) => a?.label).join('","') +
        '"}'
      : '';

  const stakeHolderName =
    selectedStakeholderName?.selectedValue?.length > 0
      ? ', [Person Stakeholder Name]={"' +
        selectedStakeholderName?.selectedValue
          ?.map((a) => a?.label)
          .join('","') +
        '"}'
      : '';

  const stakeholderType =
    selectedStakeholderType?.selectedValue?.length > 0
      ? ', [Person Stakeholder Type]={"' +
        selectedStakeholderType?.selectedValue
          ?.map((a) => a?.label)
          .join('","') +
        '"}'
      : '';
  const brand =
    selectedBrand?.selectedValue?.length > 0
      ? ', Brand={"' +
        selectedBrand?.selectedValue?.map((a) => a?.label).join('","') +
        '"}'
      : '';
  const indication =
    selectedIndication?.selectedValue?.length > 0
      ? ', Indication={"' +
        selectedIndication?.selectedValue?.map((a) => a?.label).join('","') +
        '"}'
      : '';
  const userName =
    selectedUser?.selectedValue?.length > 0
      ? ', [GNE User Name]={"' +
        selectedUser?.selectedValue?.map((a) => a?.label).join('","') +
        '"}'
      : '';
  const userRole =
    selectedRole?.selectedValue?.length > 0
      ? ', [GENE User Role]={"' +
        selectedRole?.selectedValue?.map((a) => a?.label).join('","') +
        '"}'
      : '';
  const Ecosystem =
    selectedEcosystem?.selectedValue?.length > 0
      ? ', Ecosystem={"' +
        selectedEcosystem?.selectedValue?.map((a) => a?.label).join('","') +
        '"}'
      : '';

  const replaceWith = `Date={">=$(='${moment(
    startDate,
    DEFAULT_DATE_FORMAT
  ).format(FILTER_DATE_FORMAT)}')<=$(='${moment(
    selectedEndDate,
    DEFAULT_DATE_FORMAT
  ).format(FILTER_DATE_FORMAT)}')"}, ${getCustomerExpr(
    selectedAccount,
    false
  )}${engagements}${squad}${stakeHolderName}${stakeholderType}${brand}${indication}${userName}${userRole}${Ecosystem}`;

  return replaceWith;
};

export const moveSelected = (selection, list) => {
  if (selection === undefined || selection?.length === 0) {
    return list;
  }

  let uniqueArray = [...list];
  const selected = Array.isArray(selection) ? [...selection] : [selection];

  selected.forEach((item) => {
    const index = uniqueArray.findIndex(
      (el) =>
        el.label === item.label &&
        el.value === item.value &&
        el.address === item.address
    );

    // if we find the item, remove it
    // and then add it to the top
    // otherwise just add it to the top
    if (index !== -1) {
      uniqueArray.splice(index, 1);
      uniqueArray.unshift(item);
    } else {
      uniqueArray.unshift(item);
    }
  });

  return uniqueArray;
};

export const CustomDropdownIndicator = (props) => {
  const {
    selectProps: { menuIsOpen },
  } = props;
  return (
    <components.DropdownIndicator {...props}>
      {menuIsOpen ? (
        <i className='icon icon__chevron-up' />
      ) : (
        <i className='icon icon__chevron-down' />
      )}
    </components.DropdownIndicator>
  );
};

export const getCustomerExpr = (selectedAccount, isArray) => {
  return isArray
    ? 'Customer_Id={"' + selectedAccount.map((a) => a.value).join('","') + '"}'
    : `Customer_Id={"${selectedAccount?.value}"}`;
};

export const ValueContainer = (props) => {
  const length = props?.getValue()?.length;
  const selected =
    length === 0
      ? 'All'
      : length === 1
      ? props.getValue()?.[0]?.label
      : 'Multiple Selected';

  return (
    <>
      {props?.isMulti ? (
        <>
          <span className='ps-2 text__capitalize'>{`${selected}`}</span>
          {React.cloneElement(props?.children[1])}
        </>
      ) : (
        <components.ValueContainer {...props}>
          <>{props?.children}</>
        </components.ValueContainer>
      )}
    </>
  );
};

export const dropdownOptions = ({ isSelected, children, ...rest }) => {
  return (
    <>
      <components.Option
        {...rest}
        isSelected={isSelected}>
        {rest.isMulti && (
          <div className='d-flex align-items-center text-capitalize'>
            <div>
              <input
                type='checkbox'
                checked={isSelected}
                onChange={() => {}}
                className='me-1'
              />
            </div>
            <div className='customerOptions__multi'>
              {rest.selectProps.isTitleCase
                ? capitalize(children?.props?.children[0]?.props?.children)
                : children}
            </div>
          </div>
        )}
        {!rest.isMulti && children}
      </components.Option>
    </>
  );
};

export const IconformatOptionLabel = ({ label, icon }) => {
  if (icon) {
    return (
      <div className='d-flex align-items-center'>
        <i className={`icon icon__${icon}`}></i>
        {label}
      </div>
    );
  }
  return label;
};

export const formatOptionLabel = (data, { context }) => {
  if (context === 'menu') {
    return (
      <>
        <div>{data?.label}</div>
        <div className='text__grey title__x-small'>{data?.address}</div>
        <div className='text__grey title__x-small text__capitalize'>
          {CUSTOMER_TYPE.get(data.flag)}
        </div>
      </>
    );
  }
  return data?.label;
};

export const formatCustomerFilterLabel = (data, { context }) => {
  if (context === 'menu') {
    return (
      <>
        <div className='customerOptions'>
          <div>
            {data?.label}
            <div className='text__grey title__x-small'>{data?.address}</div>
          </div>
          <div className='customerOptions type text__capitalize'>
            {CUSTOMER_TYPE.get(data.flag)}
          </div>
        </div>
      </>
    );
  }
  return data?.label;
};

export const formatNewMemberOptionLabel = (data, { context }) => {
  if (context === 'menu') {
    return (
      <>
        <span>{data?.label} </span>
        <span className='text__grey title__x-small float-end'>
          {data?.roleId}
        </span>
        <div className='text__grey title__x-small'>{data?.squad}</div>
      </>
    );
  }
  return data?.label;
};

export const formatShareOptionLabel = ({
  option,
  checked,
  onClick,
  context,
}) => {
  if (context === 'role') {
    return (
      <span className='d-flex align-center'>
        <input
          type='checkbox'
          checked={checked}
          onChange={onClick}
        />
        &nbsp;
        <span className='w-100'>{option?.label} </span>
        &nbsp;
        <span className='text__grey title__x-small'>{option?.roleId}</span>
      </span>
    );
  }
  if (context === VIEW.trends) {
    return (
      <div className='d-flex align-center'>
        <input
          type='checkbox'
          checked={checked}
          onChange={onClick}
        />
        <span className='w-100 ms-1'>
          {option?.label}
          <div className='text__grey title__x-small'>{option?.address}</div>
        </span>
      </div>
    );
  }
  return (
    <span className='d-flex align-center'>
      <input
        type='checkbox'
        checked={checked}
        onChange={onClick}
        disabled={option?.disabled}
        className={option?.disabled ? 'text__grey' : ''}
      />
      &nbsp;
      <span className={option?.disabled ? 'text__grey' : ''}>
        {option?.label}
      </span>
    </span>
  );
};

export const findFirstMatchingValue = (key, list) => {
  let dataItem = list.find((item) => item.name === key);
  return dataItem ? dataItem.value : null;
};

export const menuIsOpenFilterState = (filterName = '', flag) => {
  try {
    const filtersName = [
      'siteName',
      'ecosystem',
      'state',
      'city',
      'zip',
      'class',
      'dea',
      'hin',
      'contract_segment',
      'program_type',
      'label',
      'squad',
      'product',
    ];
    return filtersName.reduce((acc, item) => {
      if (filterName === item) {
        acc[item] = flag ?? true;
      } else {
        acc[item] = false;
      }
      return acc;
    }, {});
  } catch (error) {
    console.log(error);
    return {};
  }
};

export const buildExpressionOutOfFilter = async (
  filterData,
  userRestrictedRole,
  userRestrictedEcosystemt,
  restrictedProductSquad,
  kpiFilters = {},
  localFilters
) => {
  const accountExpression =
    filterData?.selectedAccount?.length > 0 &&
    filterData?.selectedAccount?.length !== 0
      ? getCustomerExpr(filterData?.selectedAccount, true)
      : undefined;

  const productExpression =
    userRestrictedRole?.productRestrictions === 'Y' &&
    filterData?.selectedProduct?.length === 0
      ? 'Product={"' +
        restrictedProductSquad?.map((a) => a?.product).join('","') +
        '"}'
      : filterData?.selectedProduct?.length > 0
      ? 'Product={"' +
        filterData?.selectedProduct?.map((a) => a?.label).join('","') +
        '"}'
      : undefined;

  const squadExpression =
    userRestrictedRole?.squadRestriction === 'Y' &&
    filterData?.selectedSquad?.length === 0
      ? 'Squad={"' +
        restrictedProductSquad?.map((a) => a?.squad).join('","') +
        '"}'
      : filterData?.selectedSquad?.length > 0
      ? 'Squad={"' +
        filterData?.selectedSquad?.map((a) => a?.label).join('","') +
        '"}'
      : undefined;

  const ecosystemExpression =
    !filterData?.focusAccountChecked &&
    userRestrictedRole?.isNational === 'N' &&
    filterData?.selectedEcosystem?.length === 0
      ? 'Ecosystem={"' +
        userRestrictedEcosystemt?.map((a) => a?.label).join('","') +
        '"}'
      : !filterData?.focusAccountChecked &&
        filterData?.selectedEcosystem?.length > 0
      ? 'Ecosystem={"' +
        filterData?.selectedEcosystem?.map((a) => a?.label).join('","') +
        '"}'
      : undefined;

  const dynamicKPIExpression = editDynamicExp(kpiFilters, localFilters);

  const isAccountableProductExpression =
    filterData?.accountableProduct && `isAccountableProduct={"Y"}`;

  let finalExpression = '';
  if (accountExpression !== undefined)
    finalExpression = finalExpression.concat(`${accountExpression},`);
  if (squadExpression !== undefined)
    finalExpression = finalExpression.concat(`${squadExpression},`);
  if (productExpression !== undefined)
    finalExpression = finalExpression.concat(`${productExpression},`);
  if (ecosystemExpression !== undefined)
    finalExpression = finalExpression.concat(`${ecosystemExpression},`);
  if (isAccountableProductExpression)
    finalExpression = finalExpression.concat(
      `${isAccountableProductExpression},`
    );
  if (dynamicKPIExpression !== '') {
    finalExpression = finalExpression.concat(`${dynamicKPIExpression},`);
  }
  finalExpression =
    finalExpression.charAt(finalExpression?.length - 1) === ','
      ? finalExpression?.slice(0, -1)
      : finalExpression;
  return finalExpression;
};

export const getChartTypeByDimension = (dimensions, dimensionName) => {
  const matchedDimension = dimensions.find(
    (dimension) => dimension.dimensionName === dimensionName
  );

  const barChart =
    matchedDimension.chartType === 'Bar Chart'
      ? 'bar'
      : matchedDimension.chartType === 'Line'
      ? 'line'
      : null;
  return barChart;
};

export const getRestrictedColumn = (
  tableName,
  columnData,
  restrictedColumn
) => {
  const isTableConfig =
    restrictedColumn?.length > 0
      ? restrictedColumn?.some((el) => el.tableName === tableName)
      : false;
  if (!isTableConfig) {
    return columnData;
  } else {
    let columnToExclude = restrictedColumn.map((val) => {
      if (val.tableName === tableName) {
        return val.columnName;
      } else {
        return false;
      }
    });
    return columnData.filter((col) => {
      if (columnToExclude.includes(col.dataIndex)) {
        return false;
      } else {
        return col;
      }
    });
  }
};

export const excludeColumnFromExcel = (columns) => {
  let excelColumnName = [];
  columns?.length > 0 &&
    columns?.forEach((val) => {
      excelColumnName.push(val?.hyperCubeFieldName);
    });
  return excelColumnName;
};

export const advanceExpressionToSave = (
  advanceComparePropsRef,
  vReplaceStringForDynamicFilter
) => {
  if (advanceComparePropsRef.current?.selectDropdownOption !== '')
    switch (advanceComparePropsRef.current?.compareBy) {
      case COMPARE_BENCHMARK:
        return getBenchmarkExpression(
          advanceComparePropsRef,
          vReplaceStringForDynamicFilter
        );
      case 'time-period':
        return advanceComparePropsRef.current?.selectDropdownOption?.advTimeperiodExp?.replaceAll(
          EXPRESSION_PLACEHOLDER_TEXT,
          vReplaceStringForDynamicFilter.current
        );
      case COMPARE_KPI:
        return getCompareKPIExpression(
          advanceComparePropsRef,
          vReplaceStringForDynamicFilter
        );
      default:
        return null;
    }
  return null;
};

export const getAdvanceFilterValue = (
  advanceComparePropsRef,
  vReplaceStringForDynamicFilter
) => {
  if (advanceComparePropsRef.current?.selectDropdownOption !== '')
    switch (advanceComparePropsRef.current?.compareBy) {
      // case COMPARE_BENCHMARK:
      //   return getBenchmarkFilterValues(
      //     advanceComparePropsRef,
      //     vReplaceStringForDynamicFilter
      //   );
      case COMPARE_KPI:
        return getCompareKPIFilterVallues(advanceComparePropsRef);

      default:
        return advanceComparePropsRef.current?.selectDropdownOption?.label
          ? [
              {
                label:
                  advanceComparePropsRef.current?.selectDropdownOption?.label,
                value:
                  advanceComparePropsRef.current?.selectDropdownOption?.label,
                advTimeperiodExp: advanceExpressionToSave(
                  advanceComparePropsRef,
                  vReplaceStringForDynamicFilter
                ),
              },
            ]
          : [];
    }
  return [];
};

export const buildAdvancePropsObject = (
  advanceFilterValue,
  advanceType,
  selectedDimension,
  chartType
) => {
  const advanceProps =
    advanceFilterValue === null
      ? {}
      : {
          view: 'edit',
          compareBy: advanceType,
          editDimensionName: selectedDimension,
          selectDropdownOption: {
            label:
              advanceType === COMPARE_KPI
                ? advanceFilterValue
                : advanceFilterValue[0]?.label,
            value:
              advanceType === COMPARE_KPI
                ? advanceFilterValue
                : advanceFilterValue[0]?.value,
            PossibleDimensionName:
              advanceFilterValue && advanceType === COMPARE_KPI
                ? advanceFilterValue
                : advanceFilterValue[0]?.label,
            possibleTimePeriod:
              advanceFilterValue && advanceFilterValue[0]?.label,
            advTimeperiodExp:
              advanceType === COMPARE_KPI
                ? advanceFilterValue
                : advanceFilterValue[0]?.advTimeperiodExp,
            chartType: chartType,
          },
        };
  return advanceProps;
};

const getBenchmarkExpression = (
  advanceComparePropsRef,
  vReplaceStringForDynamicFilter
) => {
  return advanceComparePropsRef.current?.selectDropdownOption?.advTimeperiodExp?.replaceAll(
    EXPRESSION_PLACEHOLDER_TEXT,
    vReplaceStringForDynamicFilter.current
  );
};

const getCompareKPIFilterVallues = (advanceComparePropsRef) => {
  let expression = advanceComparePropsRef?.current?.selectDropdownOption
    ?.PossibleDimensionName
    ? advanceComparePropsRef?.current?.selectDropdownOption
        ?.PossibleDimensionName
    : advanceComparePropsRef?.current?.selectDropdownOption;
  let finalExpression = expression?.map((val, index) => ({
    ...val,
    index: index,
  }));
  return finalExpression?.length > 0 ? finalExpression : null;
};

export const replaceUndefinedWithEmptyArray = (option) => {
  return option === undefined ? [] : option;
};

const getCompareKPIExpression = (
  advanceComparePropsRef,
  vReplaceStringForDynamicFilter
) => {
  let exp = [];
  let expression = advanceComparePropsRef?.current?.selectDropdownOption
    ?.PossibleDimensionName
    ? advanceComparePropsRef?.current?.selectDropdownOption
        ?.PossibleDimensionName
    : advanceComparePropsRef?.current?.selectDropdownOption;

  expression?.forEach((val, index) => {
    let obj = {};
    obj.index = index + 1;
    obj.expression = val?.advTimeperiodExp.replaceAll(
      EXPRESSION_PLACEHOLDER_TEXT,
      vReplaceStringForDynamicFilter.current
    );
    obj.timesPeriod = val?.label;
    exp.push(obj);
  });
  return exp.length > 0 ? exp : null;
};

export const buildExpressionCustomerViewDownload = (filterData) => {
  const accountExpression =
    filterData?.selectedAccount?.length > 0 &&
    filterData?.selectedAccount?.length !== 0
      ? getCustomerExpr(filterData?.selectedAccount, true)
      : undefined;

  const productExpression =
    filterData?.selectedProduct?.length > 0
      ? 'Product={"' +
        filterData?.selectedProduct?.map((a) => a?.label).join('","') +
        '"}'
      : undefined;

  const squadExpression =
    filterData?.selectedSquad?.length > 0
      ? 'Squad={"' +
        filterData?.selectedSquad?.map((a) => a?.label).join('","') +
        '"}'
      : undefined;
  const channelExpression =
    filterData?.selectedChannel?.length > 0
      ? 'Channel={"' +
        filterData?.selectedChannel?.map((a) => a?.label).join('","') +
        '"}'
      : undefined;
  const discussionTopicExpression =
    filterData?.selectedDiscussionTopic?.length > 0
      ? '[Discussion Topic]={"' +
        filterData?.selectedDiscussionTopic?.map((a) => a?.label).join('","') +
        '"}'
      : undefined;

  const stakeholderTypeExpression =
    filterData?.selectedStakeholderType?.length > 0
      ? '[Stakeholder Type]={"' +
        filterData?.selectedStakeholderType?.map((a) => a?.label).join('","') +
        '"}'
      : undefined;

  const templateNameExpression =
    filterData?.selectedTemplateName?.length > 0
      ? '[Template Name]={"' +
        filterData?.selectedTemplateName?.map((a) => a?.label).join('","') +
        '"}'
      : undefined;

  const surveyTypeExpression =
    filterData?.selectedSurveyType?.length > 0
      ? '[Survey Type]={"' +
        filterData?.selectedSurveyType?.map((a) => a?.label).join('","') +
        '"}'
      : undefined;

  const presentationNameExpression =
    filterData?.selectedPresentationName?.length > 0
      ? '[Presentation Name]={"' +
        filterData?.selectedPresentationName?.map((a) => a?.label).join('","') +
        '"}'
      : undefined;

  const keyMessageExpression =
    filterData?.selectedKeyMessage?.length > 0
      ? '[Key Message]={"' +
        filterData?.selectedKeyMessage?.map((a) => a?.label).join('","') +
        '"}'
      : undefined;

  const ecosystemExpression =
    filterData?.selectedEcosystem?.length > 0
      ? 'Ecosystem={"' +
        filterData?.selectedEcosystem?.map((a) => a?.label).join('","') +
        '"}'
      : undefined;

  const siteExpression =
    filterData?.selectedSiteName?.length > 0
      ? 'Site_ID={"' +
        filterData?.selectedSiteName?.map((a) => a?.value).join('","') +
        '"}'
      : undefined;

  const isAccountableProductExpression =
    filterData?.accountableProduct && `isAccountableProduct={"Y"}`;

  let finalExpression = '';
  if (accountExpression !== undefined)
    finalExpression = finalExpression.concat(`${accountExpression},`);
  if (squadExpression !== undefined)
    finalExpression = finalExpression.concat(`${squadExpression},`);
  if (productExpression !== undefined)
    finalExpression = finalExpression.concat(`${productExpression},`);
  if (ecosystemExpression !== undefined)
    finalExpression = finalExpression.concat(`${ecosystemExpression},`);
  if (isAccountableProductExpression)
    finalExpression = finalExpression.concat(
      `${isAccountableProductExpression} `
    );
  if (channelExpression !== undefined)
    finalExpression = finalExpression.concat(`,${channelExpression}`);
  if (discussionTopicExpression !== undefined)
    finalExpression = finalExpression.concat(`,${discussionTopicExpression}`);
  if (stakeholderTypeExpression !== undefined)
    finalExpression = finalExpression.concat(`,${stakeholderTypeExpression}`);
  if (templateNameExpression !== undefined)
    finalExpression = finalExpression.concat(`,${templateNameExpression}`);
  if (surveyTypeExpression !== undefined)
    finalExpression = finalExpression.concat(`,${surveyTypeExpression}`);
  if (presentationNameExpression !== undefined)
    finalExpression = finalExpression.concat(`,${presentationNameExpression}`);
  if (keyMessageExpression !== undefined)
    finalExpression = finalExpression.concat(`,${keyMessageExpression}`);
  if (siteExpression !== undefined)
    finalExpression = finalExpression.concat(`,${siteExpression}`);
  finalExpression =
    finalExpression.charAt(finalExpression?.length - 1) === ','
      ? finalExpression?.slice(0, -1)
      : finalExpression;
  return finalExpression;
};

export const createCardPayload = (
  defaultCard,
  unixId,
  positionIndexValue,
  actionType
) => {
  return {
    operation: 'POST',
    unixId,
    positionIndex: positionIndexValue,
    kpiId: defaultCard?.kpiId,
    title:
      actionType === ACTION_COPY
        ? COPY_TITLE_PREFIX + defaultCard?.title
        : defaultCard?.title,
    cardView: defaultCard?.cardView,
    cardId: null,
    filter: defaultCard?.filter,
    filters: defaultCard?.filter,
    kpiFilters: defaultCard?.kpiFilters,
    localFilters: defaultCard?.localFilters,
    chartData: defaultCard?.chartData,
    expression: defaultCard?.expression?.replace(/(^"|"$)/g, ''),
    appId: defaultCard?.appId,
    timespan: defaultCard?.timespan,
    advanceId: defaultCard?.advanceId,
    advanceEnabled: defaultCard?.advanceEnabled,
    advanceType: defaultCard?.advanceType,
    advanceExpression: defaultCard?.advanceExpression,
    adavanceTimeSpanExpression: defaultCard?.adavanceTimeSpanExpression,
    advanceFilterValue: defaultCard?.advanceFilterValue,
    action: actionType === ACTION_COPY ? ACTION_COPY : ACTION_ADD_TO_MY_VIEW,
    roleId: defaultCard.roleId,
  };
};

export const CustomMenuList = (props) => {
  const { selectProps, innerRef } = props;
  const {
    onInputChange,
    inputValue,
    onMenuInputFocus,
    handleScrolledToBottom,
    shouldLoadMore,
    searchPlaceholder,
  } = selectProps;

  const checkTimeoutRef = useRef();
  const menuListRef = useRef(null);

  const shouldHandle = useCallback(() => {
    const el = menuListRef.current;

    if (!el) {
      return false;
    }

    const { scrollTop, scrollHeight, clientHeight } = el;

    return (
      shouldLoadMore && shouldLoadMore(scrollHeight, clientHeight, scrollTop)
    );
  }, [shouldLoadMore]);

  const checkAndHandle = useCallback(() => {
    if (shouldHandle()) {
      if (handleScrolledToBottom) {
        handleScrolledToBottom();
      }
    }
  }, [shouldHandle, handleScrolledToBottom]);

  const setCheckAndHandleTimeout = useMemo(() => {
    const res = () => {
      checkAndHandle();

      checkTimeoutRef.current = setTimeout(res, selectProps.debounceTimeout);
    };

    return res;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkAndHandle]);

  useEffect(() => {
    setCheckAndHandleTimeout();

    menuListRef.current?.scrollIntoView({
      block: 'nearest',
      behavior: 'smooth',
    });

    return () => {
      if (checkTimeoutRef.current) {
        clearTimeout(checkTimeoutRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Form.Control
        className='search-box'
        type='search'
        value={inputValue}
        onChange={(e) => {
          onInputChange(e.currentTarget.value, {
            action: 'input-change',
          });
        }}
        onMouseDown={(e) => {
          e.stopPropagation();
          e.target.focus();
        }}
        onTouchEnd={(e) => {
          e.stopPropagation();
          e.target.focus();
        }}
        onFocus={onMenuInputFocus}
        placeholder={searchPlaceholder ?? 'Search'}
      />
      <components.MenuList
        {...props}
        selectProps={selectProps}
        innerRef={composeRefs(innerRef, menuListRef)}>
        {props.children}
      </components.MenuList>
    </>
  );
};

export const CustomMenuPortal = (props) => {
  return (
    <components.MenuPortal {...props}>{props.children}</components.MenuPortal>
  );
};

export const CustomValueContainer = ({ children, selectProps, ...props }) => {
  return (
    <components.ValueContainer
      {...props}
      selectProps={selectProps}>
      {React.Children.map(children, (child) => {
        return child ? (
          child
        ) : props.hasValue ? (
          <components.SingleValue
            {...props}
            isFocused={selectProps.isFocused}
            isDisabled={selectProps.isDisabled}>
            {selectProps.getOptionLabel(props.getValue()[0])}
          </components.SingleValue>
        ) : (
          <components.Placeholder
            {...props}
            key='placeholder'
            isDisabled={selectProps.isDisabled}
            data={props.getValue()}>
            {selectProps.placeholder}
          </components.Placeholder>
        );
      })}
    </components.ValueContainer>
  );
};

export const handleInputChange = (newValue, action) => {
  return newValue ? newValue : action.prevInputValue;
};

export const resolveDependencies = (stringArray = [], store) => {
  let dependencies = [];
  if (stringArray.length > 0) {
    stringArray.forEach((key) => {
      dependencies.push(findNestedValue(store, key));
    });
  }
  return dependencies;
};

const findNestedValue = (obj, path) => {
  if (obj === null || typeof obj !== 'object') {
    return undefined;
  }

  // Split the path into keys
  const keys = path.split('.');

  // Reduce the keys to find the value
  const result = keys.reduce((acc, key) => {
    return acc && acc[key] !== undefined ? acc[key] : undefined;
  }, obj);
  return result;
};

export const ConvertSingleMultiSelectValue = (value) => {
  if (!Array.isArray(value)) {
    value = value === undefined || value === null ? [] : [value];
  }
  return value;
};

export const getFlatArray = (arr = [], field = 'value') => {
  return arr?.map((item) => item[field]);
};

export const selectedLocalfilter = (
  localFilters = [],
  localFiltersData = {}
) => {
  let localFilterSave = {};
  localFilters.forEach((filter) => {
    localFilterSave['selected' + filter.filterName] =
      localFiltersData[filter.qExprClm] !== undefined
        ? localFiltersData[filter.qExprClm].value
        : [];
  });
  return localFilterSave;
};

// common method for getting card details
export const buildCardDetails = async (
  card,
  isInsight,
  userRestrictedRole,
  userRestrictedEcosystemt,
  restrictedProductSquad,
  priorityCustomer
) => {
  let advanceComparePropsRef = {};

  const { filter } = card;
  const filterData = filter ? { ...filter } : {};
  const selectedDimension = filter?.selectedKPI?.dimensions[0];

  if (card.advanceEnabled === 'Y') {
    //building advance props
    advanceComparePropsRef = buildAdvancePropsObject(
      card?.advanceFilterValue,
      card?.advanceType,
      selectedDimension?.dimensionName,
      filter?.chartType
    );
  } else {
    advanceComparePropsRef = null;
  }

  card.selectedAppLink = filterData?.selectedKPI?.appLink;
  card.expression = card.expression?.replace(/(^"|"$)/g, '');
  card.limit = '30';
  card.chartData = [];
  let customerExp = '';

  if (isInsight) {
    filterData.selectedAccount = [filterData.selectedAccount];

    customerExp = getCustomerExpr(
      filterData?.selectedAccount,
      isArray(filterData?.selectedAccount)
    );
  } else {
    if (
      filterData?.focusAccountChecked &&
      filterData?.selectedAccount?.length === 0
    ) {
      customerExp = getCustomerExpr(
        priorityCustomer,
        Array.isArray(priorityCustomer)
      );
    } else if (filterData?.selectedAccount?.length > 0) {
      customerExp = getCustomerExpr(
        filterData?.selectedAccount,
        Array.isArray(filterData?.selectedAccount)
      );
    }
  }

  let filterExpression = await buildExpressionOutOfFilter(
    filterData,
    userRestrictedRole,
    userRestrictedEcosystemt,
    restrictedProductSquad,
    card.kpiFilters,
    card.localFilters
  );
  if (customerExp !== '') {
    filterExpression = filterExpression
      ? filterExpression.concat(',', customerExp)
      : filterExpression.concat('', customerExp);
  }

  let advance = {};
  let measure = [];
  let dimension = [];
  let dimensionObjOne = {
    dimensionType: 'Cateogry',
    dimensionName: selectedDimension?.dimensionName,
  };
  dimension.push(dimensionObjOne);
  let dimensionObjTwo;

  if (
    card?.advanceEnabled === 'Y' &&
    advanceComparePropsRef?.compareBy === 'dimension'
  ) {
    card.limit = '12';
    dimensionObjTwo = {
      dimensionType: 'Advance',
      dimensionName: isArray(card?.advanceFilterValue)
        ? card?.advanceFilterValue[0]?.value
        : card?.advanceFilterValue,
    };
    dimension.push(dimensionObjTwo);
  }
  let measureObjOne = {
    timesPeriod:
      advanceComparePropsRef?.compareBy === COMPARE_BENCHMARK
        ? BENCHMARK_LEGEND_FIRST
        : advanceComparePropsRef?.compareBy === COMPARE_KPI
        ? filterData?.kpiName
        : filterData?.selectedTimePeriod?.label,
    index: 1,
    expression: card.expression
      .replaceAll(
        'vReplaceString',
        filterExpression ? filterExpression : 'test'
      )
      ?.replace(/(^"|"$)/g, ''),
  };
  measure.push(measureObjOne);
  let measureObjTwo;
  if (card?.advanceType === 'time-period') {
    card.limit = '12';
    measureObjTwo = {
      timesPeriod:
        advanceComparePropsRef?.selectDropdownOption?.possibleTimePeriod,
      index: 2,
      expression: advanceComparePropsRef?.selectDropdownOption?.advTimeperiodExp
        ?.replaceAll(
          'vReplaceString',
          filterExpression ? filterExpression : 'test'
        )
        ?.replace(/(^"|"$)/g, ''),
    };
    measure.push(measureObjTwo);
    //SETTING UP THE DIMESNION NAME TO MONTH_N IF PRIMARY DIMESNION IS MONTH AND COMPARE BY IS TIME PERIOD.
    if (selectedDimension?.dimensionName === 'Month') {
      let dimensionObjOne = {
        dimensionType: 'Cateogry',
        dimensionName: 'Month_N',
      };

      dimension = [dimensionObjOne];
    }
  }
  if (card?.advanceType === COMPARE_BENCHMARK) {
    let comapreExpression = Array.isArray(
      advanceComparePropsRef?.selectDropdownOption?.advTimeperiodExp
    )
      ? advanceComparePropsRef?.selectDropdownOption?.advTimeperiodExp[0]
          .expression
      : advanceComparePropsRef?.selectDropdownOption?.advTimeperiodExp;
    measureObjTwo = {
      timesPeriod: advanceComparePropsRef?.selectDropdownOption?.value,
      index: 2,
      expression: comapreExpression
        ?.replaceAll(
          'vReplaceString',
          filterExpression ? filterExpression : 'test'
        )
        ?.replace(/(^"|"$)/g, ''),
    };
    measure.push(measureObjTwo);
    //SETTING UP THE DIMESNION NAME TO MONTH_N IF PRIMARY DIMESNION IS MONTH AND COMPARE BY IS TIME PERIOD.
    if (selectedDimension?.dimensionName === 'Month') {
      let dimensionObjOne = {
        dimensionType: 'Cateogry',
        dimensionName: 'Month_N',
      };

      dimension = [dimensionObjOne];
    }
  }
  if (advanceComparePropsRef?.compareBy === COMPARE_KPI) {
    Array.isArray(
      advanceComparePropsRef?.selectDropdownOption?.advTimeperiodExp
    )
      ? advanceComparePropsRef?.selectDropdownOption?.advTimeperiodExp?.map(
          (oneKpi) => {
            measureObjTwo = {
              timesPeriod: oneKpi?.value,
              index: measure.length,
              expression: oneKpi.advTimeperiodExp
                ?.replaceAll(
                  'vReplaceString',
                  filterExpression ? filterExpression : 'test'
                )
                ?.replace(/(^"|"$)/g, ''),
            };
            measure.push(measureObjTwo);
            return oneKpi;
          }
        )
      : measure.push({
          timesPeriod: advanceComparePropsRef?.selectDropdownOption?.value,
          index: measure.length,
          expression:
            advanceComparePropsRef?.selectDropdownOption.advTimeperiodExp
              ?.replaceAll(
                'vReplaceString',
                filterExpression ? filterExpression : 'test'
              )
              ?.replace(/(^"|"$)/g, ''),
        });
    // measure.push(measureObjTwo);

    //SETTING UP THE DIMESNION NAME TO MONTH_N IF PRIMARY DIMESNION IS MONTH AND COMPARE BY IS TIME PERIOD.
    if (selectedDimension?.dimensionName === 'Month') {
      let dimensionObjOne = {
        dimensionType: 'Cateogry',
        dimensionName: 'Month_N',
      };

      dimension = [dimensionObjOne];
    }
  }
  advance.measure = measure;
  advance.dimension = dimension;
  advance.appId = card?.appId;

  advance.chartType = card.advanceFilterValue?.[0]?.chartType
    ? card.advanceFilterValue?.[0]?.chartType
    : filterData?.chartType;
  card.advance = advance;

  card.filterData = {};
  card.filterData.chartType = filterData?.chartType;

  return card;
};
