import React from 'react';

const ValidationMessage = ({
  filterValidation,
  enableApplyDisableAddtoButton,
}) => {
  return (
    <>
      {!filterValidation && (
        <>
          <p className='fs-6 text__bold mb-0'>
            Select one product to populate Market Share metrics.{' '}
          </p>

          <p className='fs-6 text__bold'>
            Please note that market share is not available at the moment for
            certain products.
          </p>
        </>
      )}
      {!enableApplyDisableAddtoButton && (
        <div className='title title__bold ml-1'>
          Click 'Apply' to populate chart
        </div>
      )}
    </>
  );
};

export default ValidationMessage;
