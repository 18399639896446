import 'animate.css';
import { loadDropdownOptionsv2 as loadDropdownOptions } from 'components/charts/chart.service';
import LinkButton from 'components/shared/button/LinkButton';
import Loader from 'components/shared/loader/Loader';
import NoDimension from 'components/shared/no-dimension/NoDimensions';
import {
  COMPARE_BENCHMARK,
  COMPARE_KPI,
  COMPARE_TIME_PERIOD,
  DIMENSION,
} from 'constants/constants';
import { useEffect, useRef, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { usePromiseTracker } from 'react-promise-tracker';
import { useDispatch, useSelector } from 'react-redux';
import Chart from '../charts/Echart';
import TableView from '../charts/TableView';
import classes from './Chart.module.scss';

import ValidationMessage from 'components/shared/tiles/ValidationMessage';
import {
  setAdvanceButtonStatus,
  setAdvanceOptions,
  setSelectedCardView,
} from 'store/trends_v2/trendsSlice';
import AdvanceFilter from './AdvanceFilter';
import { isEmpty } from 'lodash';

const ChartAndTableView = ({
  page,
  timespan,
  onResetClicked,
  filterValidation,
  enableApplyDisableAddtoButton,
}) => {
  const dispatch = useDispatch();

  const view = useSelector(
    (state) => state?.trends?.selectedCardView ?? 'chart'
  );

  const noDimensionValue = useSelector(
    (state) => state?.trends?.noDimensionValue
  );
  const benchmarkNoDimensionValue = useSelector(
    (state) => state?.trends?.benchmarkNoDimensionValue
  );
  const selectedKPI = useSelector((state) => state?.trends?.selectedKPI);
  const selectedDimension = useSelector(
    (state) => state?.trends?.selectedDimension
  );
  const chartData = useSelector((state) => state?.trends?.chartData);
  const chartType = useSelector((state) => state?.trends?.chartType);

  const advanceButtonValue = useSelector(
    (state) => state?.trends?.advanceButtonValue
  );

  const { value: selectedTimePeriod } = useSelector(
    (state) => state.trends.KPIFilter.TimePeriod
  );
  const selectedAdvanceOptions = useSelector(
    (state) => state?.trends?.advanceOptions
  );
  const compareByType = useSelector(
    (state) => state?.trends?.advanceOptions?.compareBy
  );

  const showChart =
    chartData?.values[0] !== null &&
    chartData?.values[0] !== undefined &&
    selectedDimension?.dimensionName !== 'No Dimension';

  const isColor =
    selectedKPI?.KpiName?.slice(-3) === '(%)' ||
    selectedKPI?.KpiName?.toLowerCase().includes('change');

  const isValue = noDimensionValue > 0;
  const noData = chartData.values?.length === 0 && !noDimensionValue;

  const { promiseInProgress } = usePromiseTracker();

  const [showAdvanceOption, setShowAdvanceOption] = useState(false);
  const firstRenderTimePeriod = useRef(false);

  const handleAdvanceClick = (e) => {
    setShowAdvanceOption(!showAdvanceOption);
    dispatch(
      setAdvanceButtonStatus(
        advanceButtonValue === false && e?.type === 'click' ? true : false
      )
    );
  };
  const handleReset = () => {
    onResetClicked();
    loadDropdownOptions(
      selectedDimension?.advanceOptions[0] ?? DIMENSION,
      dispatch,
      selectedKPI,
      selectedTimePeriod,
      selectedDimension
    );

    dispatch(
      setAdvanceOptions({
        compareBy: selectedDimension?.advanceOptions[0] ?? DIMENSION,
        selectDropdownOption: '',
      })
    );
  };

  const handleChartViewClicked = (cardView) => {
    dispatch(setSelectedCardView(cardView));
  };

  //on opening of advance button
  useEffect(() => {
    dispatch(setAdvanceButtonStatus(false));
    // eslint-disable-next-line
  }, []);

  //on change of primary dimension
  useEffect(() => {
    if (showAdvanceOption && selectedTimePeriod.length !== 0) {
      // Reset Advance option only if advance popup is open and call API
      dispatch(
        setAdvanceOptions({
          compareBy: selectedDimension?.advanceOptions[0] ?? DIMENSION,
        })
      );
      loadDropdownOptions(
        selectedDimension?.advanceOptions[0] ?? DIMENSION,
        dispatch,
        selectedKPI,
        selectedTimePeriod,
        selectedDimension
      );
      firstRenderTimePeriod.current = false;
      dispatch(setAdvanceButtonStatus(false));
      handleAdvanceClick();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDimension?.dimensionId]);

  //on change of primary timee-period
  useEffect(() => {
    if (selectedTimePeriod?.length !== 0) {
      if (firstRenderTimePeriod.current) {
        if (
          compareByType === COMPARE_TIME_PERIOD ||
          compareByType === COMPARE_BENCHMARK ||
          compareByType === COMPARE_KPI
        ) {
          let options = {
            ...selectedAdvanceOptions,
            selectDropdownOption: '',
          };

          dispatch(setAdvanceOptions(options));

          loadDropdownOptions(
            compareByType,
            dispatch,
            selectedKPI,
            selectedTimePeriod,
            selectedDimension
          );
        }
      }
      firstRenderTimePeriod.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTimePeriod?.label]);

  return (
    <Card className={`${classes['chart-container']} pe-0`}>
      <Card.Body>
        <Card.Subtitle>
          <div className={classes['chart-title']}>
            <div>
              {enableApplyDisableAddtoButton && (
                <label className='sub-title sub-title__x-small me-2 text text__grey'>
                  {timespan}
                </label>
              )}
              {showChart && !noData && (
                <LinkButton
                  onClick={() => handleChartViewClicked('chart')}
                  className='d-inline-block p-0 mt-1'
                  disabled={
                    (!isEmpty(selectedAdvanceOptions?.selectDropdownOption) &&
                      compareByType === COMPARE_KPI) ||
                    !enableApplyDisableAddtoButton
                  }>
                  <i
                    className={`icon icon__chartView${
                      view === 'chart' && enableApplyDisableAddtoButton
                        ? '--active'
                        : ''
                    }  ms-2 border-0`}></i>
                </LinkButton>
              )}
              {showChart && (
                <LinkButton
                  onClick={() => handleChartViewClicked('table')}
                  className='d-inline-block p-0 mt-1'
                  disabled={!enableApplyDisableAddtoButton}>
                  <i
                    className={`icon icon__tableView${
                      view === 'table' ? '--active' : ''
                    } border-0`}></i>
                </LinkButton>
              )}
              {showChart && (
                <LinkButton
                  onClick={() => handleChartViewClicked('hash')}
                  className='d-inline-block p-0 mt-1'
                  disabled={
                    !enableApplyDisableAddtoButton ||
                    selectedAdvanceOptions?.selectDropdownOption?.label ||
                    (!isEmpty(selectedAdvanceOptions?.selectDropdownOption) &&
                      compareByType === COMPARE_KPI)
                      ? true
                      : false
                  }>
                  <i
                    className={`icon icon__hashtag${
                      view === 'hash' && enableApplyDisableAddtoButton
                        ? '--active'
                        : ''
                    }  border-0`}></i>
                </LinkButton>
              )}
            </div>
            <div className='d-flex'>
              <Button
                variant={`btn btn-gne btn-secondary text text__x-small pb-1 pt-1 me-2 w-15 ${
                  showAdvanceOption ? 'active' : ''
                } ${
                  selectedDimension?.advanceOptions?.length > 0
                    ? classes.configure
                    : classes.configure__disable
                } `}
                disabled={!selectedDimension?.advanceOptions?.length > 0}
                onClick={(e) => {
                  handleAdvanceClick(e);
                }}>
                <i
                  className={`'icon__configure-advance' pt-1 icon ${
                    showAdvanceOption ||
                    !selectedDimension?.advanceOptions?.length > 0
                      ? 'icon__configure-advance-active'
                      : 'icon__configure-advance'
                  }`}></i>
                Advanced
              </Button>
              <LinkButton
                className={`text text__x-small mt-1 ms-1 
                  ${
                    (selectedAdvanceOptions?.selectDropdownOption?.label &&
                      selectedAdvanceOptions?.selectDropdownOption?.label
                        ?.length !== 0) ||
                    selectedAdvanceOptions?.selectDropdownOption?.length > 0
                      ? ''
                      : 'disabled'
                  }`}
                onClick={handleReset}
                disabled={
                  (selectedAdvanceOptions?.selectDropdownOption?.label &&
                    selectedAdvanceOptions?.selectDropdownOption?.label
                      ?.length !== 0) ||
                  selectedAdvanceOptions?.selectDropdownOption?.length > 0
                    ? false
                    : true
                }>
                {
                  //Reset Icon
                  (selectedAdvanceOptions?.selectDropdownOption?.label &&
                    selectedAdvanceOptions?.selectDropdownOption?.label
                      ?.length !== 0) ||
                  selectedAdvanceOptions?.selectDropdownOption?.length > 0 ? (
                    <i className='icon icon__reset mb-1 '></i>
                  ) : (
                    <i className='icon icon__reset-disabled mb-1'></i>
                  )
                }
                Reset
              </LinkButton>
            </div>
          </div>
          {showAdvanceOption && <AdvanceFilter />}
        </Card.Subtitle>
        <div className={`mt-2 ${classes['table-chart-container']}`}>
          {!promiseInProgress &&
          (!filterValidation || !enableApplyDisableAddtoButton) ? (
            <ValidationMessage
              filterValidation={filterValidation}
              enableApplyDisableAddtoButton={enableApplyDisableAddtoButton}
            />
          ) : noData && !promiseInProgress ? (
            <i className='icon icon__no-data'></i>
          ) : (
            <>
              {view !== 'table' && showChart && (
                <Chart
                  chartData={chartData}
                  kpiName={selectedKPI?.KpiName}
                  chartType={chartType}
                  hashView={view === 'hash'}
                />
              )}

              {!showChart && (
                <>
                  {promiseInProgress ? (
                    <Loader component='chart' />
                  ) : (
                    noDimensionValue !== '' &&
                    selectedDimension?.dimensionName === 'No Dimension' && (
                      <NoDimension
                        isColor={isColor}
                        noDimensionValue={noDimensionValue}
                        benchmarkNoDimensionValue={benchmarkNoDimensionValue}
                        kpiName={selectedKPI?.KpiName}
                        isValue={isValue}
                      />
                    )
                  )}
                </>
              )}

              {view === 'table' && showChart && (
                <div className={`mt-2 ${classes['table-container']}`}>
                  <TableView
                    data={chartData}
                    kpiName={selectedKPI?.KpiName}
                    dimensionName={selectedDimension?.dimensionName}
                    chartType={chartType}
                    page={'chart'}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default ChartAndTableView;
