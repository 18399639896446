import React, { useEffect, useState, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import GNESingleSelect from 'components/shared/dropdown/GNESingleSelect';
import classes from './AdvanceFilter.module.scss';
import { isArray } from 'utils/utils';
import {
  ADVANCE_FILTER,
  ADVANCE_FILTERS,
  ADVANCE_FILTERS_OPTIONS,
  COMPARE_BENCHMARK,
  COMPARE_KPI,
  COMPARE_TIME_PERIOD,
  DIMENSION,
} from 'constants/constants';
import MultiSelectDropdown from 'components/home/configure-my-view/MultiSelectDropdown';
import { useDispatch, useSelector } from 'react-redux';

import { loadDropdownOptionsv2 as loadDropdownOptions } from 'components/charts/chart.service';
import {
  setAdvanceOptions,
  setSelectedCardView,
} from 'store/trends_v2/trendsSlice';

const AdvanceFilter = () => {
  const firstLoad = useRef(true);
  const dispatch = useDispatch();

  const [placeholder, setPlaceholder] = useState('Add Dimension');
  const [multiKpiOptions, setMultiKpiOptions] = useState([]);
  const [loadInitialCompareOpts, setLoadInitialCompareOpts] = useState(true);

  const advanceOptions = useSelector((state) => state?.trends?.advanceOptions);
  const { selectDropdownOption, compareBy } = advanceOptions ?? {};

  const selectedDimension = useSelector(
    (state) => state?.trends?.selectedDimension
  );
  const selectedKPI = useSelector((state) => state?.trends?.selectedKPI);
  const { value: selectedTimePeriod } = useSelector(
    (state) => state.trends.KPIFilter.TimePeriod
  );
  const advanceDropdownOptions = useSelector(
    (state) => state?.trends?.advanceDropdownOptions
  );
  const KPIselectedStatus = useSelector(
    (state) => state?.trends?.KPIselectedStatus
  );

  useEffect(() => {
    if (loadInitialCompareOpts) {
      if (!advanceOptions) {
        dispatch(
          setAdvanceOptions({
            compareBy: selectedDimension?.advanceOptions[0] ?? DIMENSION,
          })
        );
        loadDropdownOptions(
          selectedDimension?.advanceOptions[0] ?? DIMENSION,
          dispatch,
          selectedKPI,
          selectedTimePeriod,
          selectedDimension
        );
      } else {
        loadDropdownOptions(
          advanceOptions?.compareBy,
          dispatch,
          selectedKPI,
          selectedTimePeriod,
          selectedDimension,
          advanceOptions
        );
      }
      setLoadInitialCompareOpts(false);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setMultiKpiOptions([]);
  }, [selectedTimePeriod?.label]);

  const setPlaceholderValue = (value) => {
    switch (value) {
      case COMPARE_TIME_PERIOD:
        setPlaceholder('Select Time Period');
        break;
      case DIMENSION:
        setPlaceholder('Select Dimension');
        break;
      case COMPARE_BENCHMARK:
        setPlaceholder('Select Benchmark');
        break;
      case COMPARE_KPI:
        setPlaceholder('Select KPI');
        break;
      default: {
        setPlaceholder('Select Option');
      }
    }
  };

  // on change of advance dropdown option
  useEffect(() => {
    if (!advanceOptions?.selectDropdownOption)
      setPlaceholderValue(advanceOptions?.compareBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advanceOptions]);

  const onChangeAdvanceCompareOption = (value) => {
    loadDropdownOptions(
      value,
      dispatch,
      selectedKPI,
      selectedTimePeriod,
      selectedDimension
    );
    setPlaceholderValue(value);
    let setOnTypeChange = {
      compareBy: value,
      selectDropdownOption: '',
    };

    dispatch(setAdvanceOptions(setOnTypeChange));
  };

  const handleDropdownSelection = (selected) => {
    let setOnDropdownOptChange = {
      ...advanceOptions,
      selectDropdownOption: selected,
    };

    dispatch(setAdvanceOptions(setOnDropdownOptChange));

    if (compareBy === COMPARE_KPI) {
      if (selected.length === 0) {
        setMultiKpiOptions([]);
      }
      if (selected.length >= 4) {
        firstLoad.current = false;
        setMultiKpiOptions(
          advanceDropdownOptions?.map((item) => ({
            ...item,
            disabled:
              selected.filter((sel) => sel.label === item.label)?.length > 0
                ? false
                : true,
          }))
        );
      } else {
        let isEditView =
          setOnDropdownOptChange?.view === 'edit' && firstLoad.current === true;

        let newOpts = [];

        if (isEditView) {
          newOpts = advanceDropdownOptions.map((val) => ({
            ...val,
            disabled: false,
          }));
        }

        if (!firstLoad.current) {
          newOpts = multiKpiOptions.map((val) => ({
            ...val,
            disabled: false,
          }));
          firstLoad.current = true;
        }
        if (newOpts?.length > 0) setMultiKpiOptions(newOpts);
      }
    }
  };

  const setTileView = (view) => {
    setMultiKpiOptions([]);
    dispatch(setSelectedCardView(view));
  };

  return (
    <>
      <div className={`${classes['filter-container']} `}>
        <Row className='title__x-small'>
          <Col
            md={8}
            className={`title__x-small d-flex justify-content-space-between mt-2 ${classes['advance-radio-btn-ipad-align']}`}>
            {selectedDimension?.advanceOptions?.map((option) => {
              const optionConfig = ADVANCE_FILTERS_OPTIONS[option];
              if (!optionConfig) {
                return null;
              }
              return (
                <label
                  key={option}
                  className={`d-flex justify-content-start ${classes['Advance_radio_button']}`}>
                  <input
                    type='radio'
                    name='advance'
                    value={compareBy}
                    className={classes['radio_trend']}
                    onChange={() => {
                      onChangeAdvanceCompareOption(optionConfig.value);
                      setTileView(optionConfig.tileView);
                    }}
                    checked={compareBy === optionConfig.value}
                    placeholder={optionConfig.placeholder}
                  />
                  <span className='ms-1 title__x-small'>
                    {optionConfig.label}
                  </span>
                </label>
              );
            })}
          </Col>
          <Col
            md={4}
            className={`mt-2 ${classes['advance-kpi-dropdown-ipad-align']}`}>
            {compareBy === COMPARE_KPI ? (
              <MultiSelectDropdown
                options={
                  multiKpiOptions.length > 0
                    ? multiKpiOptions
                    : advanceDropdownOptions
                }
                selected={
                  selectDropdownOption?.[0] === undefined &&
                  !isArray(selectDropdownOption?.label)
                    ? []
                    : isArray(selectDropdownOption?.label)
                    ? selectDropdownOption?.label
                    : selectDropdownOption
                }
                setSelected={handleDropdownSelection}
                hasSelectAll={false}
                className=''
                page={ADVANCE_FILTER}
                loading={KPIselectedStatus}
              />
            ) : (
              <GNESingleSelect
                value={selectDropdownOption}
                onChange={handleDropdownSelection}
                options={advanceDropdownOptions}
                placeholder={placeholder}
                containerCss={
                  'd-flex justify-content-right align-content-center'
                }
                className={`single-select ${classes.actionsDropdown}`}
              />
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default AdvanceFilter;
