import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { formatLabel } from '../../utils/utils';
import uuid from 'react-native-uuid';

const TableView = ({
  data: {
    category,
    values,
    compareDimensionName = '',
    compareAdvanceName = '',
    timePeriod = '',
  },
  kpiName,
  dimensionName,
  page = '',
}) => {
  const [col, setCols] = useState();
  const [dataSource, setDataSource] = useState();

  const getColumnWidth = (colName = '') => {
    const pixel = 8;
    const width = colName?.length * pixel;
    return width < 140 ? 140 : width;
  };
  const generateColumns = (values) => {
    let cols = [];
    cols.push({
      id: 1,
      dataIndex: 'dimensionName',
      key: dimensionName,
      title: dimensionName,
      isMandatory: 'Y',
      width: getColumnWidth(dimensionName),

      fixed: 'left',
    });
    compareDimensionName && compareAdvanceName
      ? cols.push(
          {
            id: 2,
            dataIndex: compareDimensionName,
            key: compareDimensionName,
            title: compareDimensionName,
            isMandatory: 'Y',
            width: getColumnWidth(compareDimensionName),
          },
          {
            id: 3,
            dataIndex: compareAdvanceName,
            key: compareAdvanceName,
            title: compareAdvanceName,
            isMandatory: 'Y',
            width: getColumnWidth(compareAdvanceName),
          }
        )
      : values?.[0] && values?.[0]['name']
      ? values?.forEach((val, index) => {
          cols.push({
            id: index,
            dataIndex: val?.name,
            key: val.name,
            title: val.name,
            isMandatory: 'Y',
            width: getColumnWidth(val.name),
          });
        })
      : cols.push({
          id: 1,
          dataIndex: 'kpiName',
          key: dimensionName,
          title: kpiName,
          isMandatory: 'N',
          width: getColumnWidth(kpiName),
        });
    setCols(cols);
  };
  const generateData = (values) => {
    let dataSource = [];
    if (category) {
      compareDimensionName && compareAdvanceName
        ? values[0]?.data.map((x, index) =>
            values?.forEach((val) => {
              if (!formatLabel(val.data[index], kpiName).includes('NaN')) {
                let obj = {};
                obj[compareDimensionName] = val?.name;
                obj['dimensionName'] = category[index];
                obj[compareAdvanceName] = formatLabel(
                  val.data[index],
                  kpiName
                ).includes('NaN')
                  ? '-'
                  : formatLabel(val.data[index], kpiName);
                obj.key = uuid.v4();
                dataSource.push(obj);
              }
            })
          )
        : category?.flat().forEach((cat, index) => {
            let obj = {};
            obj.dimensionName = cat;

            values?.[0] && values?.[0]['name']
              ? values?.forEach((val) => {
                  obj[val?.name] = formatLabel(
                    val?.data[index],
                    val?.name
                  ).includes('NaN')
                    ? '-'
                    : formatLabel(val?.data[index], val?.name);
                })
              : (obj['kpiName'] = formatLabel(values?.[index], kpiName));
            obj.key = uuid.v4();
            dataSource.push(obj);
          });
    }
    setDataSource(dataSource);
  };

  useEffect(() => {
    generateData(values);
    generateColumns(values);
    // eslint-disable-next-line
  }, [values]);

  return (
    <Table
      dataSource={dataSource}
      columns={col}
      className='custom-table gne-table engagement-table'
      pagination={false}
      scroll={{
        // x: 700,
        y:
          page === 'tile' && dataSource?.length === 0
            ? 130
            : page === 'tile'
            ? 125
            : page === 'expand'
            ? 375
            : 230,
      }}
    />
  );
};

export default TableView;
